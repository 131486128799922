import React, { FC } from 'react';

import { Svg } from '@components/core';

interface IOwnProps extends React.SVGAttributes<SVGSVGElement> {
	className?: string;
}

export const AboutBrandGlueDesktop: FC<IOwnProps> = ({ className = '' }) => (
	<Svg
		aria-labelledby="brandglueLogoTitle"
		className={className}
		role="img"
		viewBox="0 0 963 1749.7"
		xmlns="http://www.w3.org/2000/svg"
	>
<path fill="#7CB0C2" d="M339,401.1L339,401.1c21.2-17.1,33.6-42.8,33.6-70.1v-16c0-27.7,12.8-53.9,34.8-70.9l23.8-18.4
	c21.7-16.8,34.4-42.7,34.4-70.2V142h24v28.5c0,27.4-15.7,53.4-37.4,70.2l-23.8,18.4c-21.9,17-31.8,39.2-31.8,66.9v16
	c0,20.6-10,44.3-22.6,60.1L339,401.1"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-7.7264" y1="-831.3821" x2="1019.1498" y2="392.4011" gradientTransform="matrix(1 0 0 1 0 982)">
<stop  offset="0" stopColor="#828487"/>
	<stop  offset="0.1976" stopColor="#778392"/>
	<stop  offset="0.55" stopColor="#5981B1"/>
	<stop  offset="1" stopColor="#2A7DE1"/>
</linearGradient>
<path fill="none" stroke="url(#SVGID_1_)" strokeMiterlimit="10" strokeWidth="6" d="M6,142.1h761c71.8,0,130,58.2,130,130l0,0c0,71.8-58.2,130-130,130H189.5C124.6,402.1,72,454.7,72,519.6l0,0
	c0,64.9,52.6,117.5,117.5,117.5h570c75.9,0,137.5,61.6,137.5,137.5l0,0c0,75.9-61.6,137.5-137.5,137.5H235.7
	c-89.4,0-161.8,72.4-161.8,161.8l0,0c0,89.4,72.4,161.8,161.8,161.8h499.5c89.4,0,161.8,72.4,161.8,161.8l0,0
	c0,89.4-72.4,161.8-161.8,161.8H539.3H330.4"/>
<circle fill="#005ADA" cx="72" cy="142.1" r="66"/>
<circle fill="#FFAD00" cx="624" cy="142.1" r="26"/>
<circle fill="#FFAD00" cx="457" cy="401.1" r="36"/>
<circle fill="#005ADA" cx="892" cy="275.1" r="66"/>
<circle fill="#005ADA" cx="892" cy="780.1" r="66"/>
<circle fill="#005ADA" cx="72" cy="516.1" r="66"/>
<rect x="6" y="225.1" fill="none" width="456" height="100"/>
<text transform="matrix(1 0 0 1 5.9997 239.9696)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2009</text>
<text transform="matrix(1 0 0 1 5.9997 257.9696)" font-family="Lato" fontSize="18">BrandGlue is born!</text>
<text transform="matrix(1 0 0 1 5.9997 283.9696)" font-family="Lato" fontSize="18">Started in a college dorm room,  </text>
<text transform="matrix(1 0 0 1 5.9997 301.9696)" font-family="Lato" fontSize="18">focused solely on Facebook marketing</text>
<rect x="311" y="38.9" fill="none" width="228" height="100"/>
<text transform="matrix(1 0 0 1 310.9997 53.7351)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2010</text>
<text transform="matrix(1 0 0 1 310.9997 71.7351)" font-family="Lato" fontSize="18">Team grows to 4 people and </text>
<text transform="matrix(1 0 0 1 310.9997 89.7351)" font-family="Lato" fontSize="18">3 cats, expands to LinkedIn </text>
<text transform="matrix(1 0 0 1 310.9997 107.7351)" font-family="Lato" fontSize="18">and Twitter marketing</text>
<rect x="598" y="39.3" fill="none" width="269.3" height="100"/>
<text transform="matrix(1 0 0 1 597.9997 54.1694)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2011</text>
<text transform="matrix(1 0 0 1 597.9997 72.1694)" font-family="Lato" fontSize="18">Helped the Washington Football </text>
<text transform="matrix(1 0 0 1 597.9997 90.1694)" font-family="Lato" fontSize="18">Club become the fastest growing </text>
<text transform="matrix(1 0 0 1 597.9997 108.1694)" font-family="Lato" fontSize="18">NFL team on Facebook</text>
<rect x="598" y="234.1" fill="none" width="228" height="100"/>
<text transform="matrix(1 0 0 1 597.9997 248.9402)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2012</text>
<text transform="matrix(1 0 0 1 597.9997 266.9402)" font-family="Lato" fontSize="18">Helped YouTube become  </text>
<text transform="matrix(1 0 0 1 597.9997 284.9402)" font-family="Lato" fontSize="18">the fastest growing fan page </text>
<text transform="matrix(1 0 0 1 597.9997 302.9402)" font-family="Lato" fontSize="18">on all of Facebook</text>
<rect x="425" y="454.8" fill="none" width="228" height="100"/>
<text transform="matrix(1 0 0 1 424.9995 469.6317)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2013</text>
<text transform="matrix(1 0 0 1 424.9995 487.6317)" font-family="Lato" fontSize="18">Team grows to 6 people,  </text>
<text transform="matrix(1 0 0 1 424.9995 505.6317)" font-family="Lato" fontSize="18">4 cats, 1 dog, 1 horse, </text>
<text transform="matrix(1 0 0 1 424.9995 523.6317)" font-family="Lato" fontSize="18">expands to Instagram </text>
<text transform="matrix(1 0 0 1 424.9995 541.6317)" font-family="Lato" fontSize="18">marketing</text>
<rect x="257" y="454.8" fill="none" width="127.1" height="100"/>
<text transform="matrix(1 0 0 1 256.9997 469.6317)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2014</text>
<text transform="matrix(1 0 0 1 256.9997 487.6317)" font-family="Lato" fontSize="18">Team speaks at </text>
<text transform="matrix(1 0 0 1 256.9997 505.6317)" font-family="Lato" fontSize="18">SXSW on social </text>
<text transform="matrix(1 0 0 1 256.9997 523.6317)" font-family="Lato" fontSize="18">media</text>
<rect x="8.4" y="670.1" fill="none" width="165.1" height="100"/>
<text transform="matrix(1 0 0 1 8.4518 684.9861)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2015</text>
<text transform="matrix(1 0 0 1 8.4518 702.9861)" font-family="Lato" fontSize="18">Helped grow </text>
<text transform="matrix(1 0 0 1 8.4518 720.9861)" font-family="Lato" fontSize="18">Eloqua&apos;s Facebook </text>
<text transform="matrix(1 0 0 1 8.4518 738.9861)" font-family="Lato" fontSize="18">community by </text>
<text transform="matrix(1 0 0 1 8.4518 756.9861)" font-family="Lato" fontSize="18">2,500%</text>
<rect x="271" y="670.1" fill="none" width="171" height="141.9"/>
<text transform="matrix(1 0 0 1 270.9997 684.9861)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2016</text>
<text transform="matrix(1 0 0 1 270.9997 702.9861)" font-family="Lato" fontSize="18">Partnered on client </text>
<text transform="matrix(1 0 0 1 270.9997 720.9861)" font-family="Lato" fontSize="18">case study (Jackson </text>
<text transform="matrix(1 0 0 1 270.9997 738.9861)" font-family="Lato" fontSize="18">Kayak) with Social </text>
<text transform="matrix(1 0 0 1 270.9997 756.9861)" font-family="Lato" fontSize="18">Media Examiner</text>
<text transform="matrix(1 0 0 1 270.9997 786.9861)" font-family="Lato" fontSize="18">Case study trended </text>
<text transform="matrix(1 0 0 1 270.9997 804.9861)" font-family="Lato" fontSize="18">on Slideshare.</text>
<rect x="624" y="670.1" fill="none" width="189.1" height="100"/>
<text transform="matrix(1 0 0 1 623.9997 684.9861)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2017</text>
<text transform="matrix(1 0 0 1 623.9997 702.9861)" font-family="Lato" fontSize="18">Team expands to all </text>
<text transform="matrix(1 0 0 1 623.9997 720.9861)" font-family="Lato" fontSize="18">U.S. mainland </text>
<text transform="matrix(1 0 0 1 623.9997 738.9861)" font-family="Lato" fontSize="18">timezones </text>
<rect x="511.9" y="943.8" fill="none" width="238.9" height="160.5"/>
<text transform="matrix(1 0 0 1 447.9275 958.6517)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2019</text>
<text transform="matrix(1 0 0 1 447.9275 976.6517)" font-family="Lato" fontSize="18">Clients spread across the B2B </text>
<text transform="matrix(1 0 0 1 447.9275 994.6516)" font-family="Lato" fontSize="18">tech landscape. </text>
<text transform="matrix(1 0 0 1 447.9275 1024.6516)" font-family="Lato" fontSize="18">Instagram influencer program </text>
<text transform="matrix(1 0 0 1 447.9275 1042.6516)" font-family="Lato" fontSize="18">for B2B companies a big </text>
<text transform="matrix(1 0 0 1 447.9275 1060.6516)" font-family="Lato" fontSize="18">success.</text>
<rect x="83.1" y="1512.9" fill="none" width="188.6" height="92.8"/>
<text transform="matrix(1 0 0 1 83.1063 1527.8149)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">Today</text>
<text transform="matrix(1 0 0 1 83.1063 1545.8149)" font-family="Lato" fontSize="18">Large team managing over</text>
<text transform="matrix(1 0 0 1 83.1063 1567.4149)" font-family="Lato" fontSize="18">112 social profiles,</text>
<text transform="matrix(1 0 0 1 83.1063 1589.0149)" font-family="Lato" fontSize="18">21 Hootsuite accounts,</text>
<text transform="matrix(1 0 0 1 83.1063 1610.615)" font-family="Lato" fontSize="18">15 animals, and 13 kids.</text>
<rect x="285" y="1253.8" fill="none" width="238.9" height="160.5"/>
<text transform="matrix(1 0 0 1 264.9997 1288.6375)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2021</text>
<text transform="matrix(1 0 0 1 264.9997 1306.6375)" font-family="Lato" fontSize="18">B2B client list grows across </text>
<text transform="matrix(1 0 0 1 264.9997 1324.6375)" font-family="Lato" fontSize="18">sectors, from cybersecurity to </text>
<text transform="matrix(1 0 0 1 264.9997 1342.6375)" font-family="Lato" fontSize="18">multi-cloud networking to </text>
<text transform="matrix(1 0 0 1 264.9997 1360.6375)" font-family="Lato" fontSize="18">healthcare.</text>
<rect x="674.9" y="1350.3" fill="none" width="238.9" height="160.5"/>
<text transform="matrix(1 0 0 1 674.9359 1365.2119)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2022</text>
<rect x="624" y="788.1" fill="none" width="189.1" height="100"/>
<text transform="matrix(1 0 0 1 623.9997 803.0027)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2018</text>
<text transform="matrix(1 0 0 1 623.9997 821.0027)" font-family="Lato" fontSize="18">Team grows to 10 </text>
<text transform="matrix(1 0 0 1 623.9997 839.0027)" font-family="Lato" fontSize="18">people, 4 cats, 3 dog,  </text>
<text transform="matrix(1 0 0 1 623.9997 857.0027)" font-family="Lato" fontSize="18">1 horse, 2 babies</text>
<rect x="132" y="1033.4" fill="none" width="279.6" height="100"/>
<text transform="matrix(1 0 0 1 131.9747 1048.2246)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2020</text>
<text transform="matrix(1 0 0 1 131.9747 1066.2246)" font-family="Lato" fontSize="18">Team helps all clients survive and </text>
<text transform="matrix(1 0 0 1 131.9747 1084.2246)" font-family="Lato" fontSize="18">thrive with social media efforts </text>
<text transform="matrix(1 0 0 1 131.9747 1102.2246)" font-family="Lato" fontSize="18">during the Coronavirus pandemic</text>
<g>
	<rect x="517.9" y="337.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="509,325.6 521,293.6 533,325.6 	"/>
	<polygon fill="#3B7558" points="503.6,348.9 521,302.4 538.4,348.9 	"/>
</g>
<g>
	<rect x="186" y="588" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="177.1,576.5 189.1,544.5 201.1,576.5 	"/>
	<polygon fill="#3B7558" points="171.7,599.7 189.1,553.3 206.5,599.7 	"/>
</g>
<g>
	<rect x="226.4" y="564.8" fill="#B09F8E" width="5.1" height="19.8"/>
	<polygon fill="#3B7558" points="218.9,555.1 229,528 239.1,555.1 	"/>
	<polygon fill="#3B7558" points="214.3,574.7 229,535.5 243.7,574.7 	"/>
</g>
<g>
	<rect x="78.2" y="1346.8" fill="#B09F8E" width="5.1" height="19.8"/>
	<polygon fill="#3B7558" points="70.8,1337.1 80.9,1310 91,1337.1 	"/>
	<polygon fill="#3B7558" points="66.2,1356.7 80.9,1317.4 95.6,1356.7 	"/>
</g>
<g>
	<rect x="145.2" y="1691.1" fill="#B09F8E" width="7.1" height="27.9"/>
	<polygon fill="#3B7558" points="134.7,1677.4 149,1639.4 163.2,1677.4 	"/>
	<polygon fill="#3B7558" points="128.3,1705.1 149,1649.8 169.6,1705.1 	"/>
</g>
<g>
	<rect x="515.4" y="1444.8" fill="#B09F8E" width="5.1" height="19.8"/>
	<polygon fill="#3B7558" points="507.9,1435.1 518,1408.1 528.1,1435.1 	"/>
	<polygon fill="#3B7558" points="503.3,1454.7 518,1415.5 532.7,1454.7 	"/>
</g>
<g>
	<rect x="611.3" y="1187.3" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="602.5,1175.8 614.4,1143.8 626.4,1175.8 	"/>
	<polygon fill="#3B7558" points="597,1199 614.4,1152.6 631.8,1199 	"/>
</g>
<g>
	<rect x="651.8" y="1164.1" fill="#B09F8E" width="5.1" height="19.8"/>
	<polygon fill="#3B7558" points="644.3,1154.4 654.4,1127.4 664.5,1154.4 	"/>
	<polygon fill="#3B7558" points="639.7,1174 654.4,1134.8 669.1,1174 	"/>
</g>
<g>
	<rect x="174" y="99.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="165.1,87.6 177.1,55.6 189.1,87.6 	"/>
	<polygon fill="#3B7558" points="159.7,110.9 177.1,64.4 194.5,110.9 	"/>
</g>
<g>
	<rect x="191.4" y="99.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="182.5,115.1 194.5,83.1 206.5,115.1 	"/>
</g>
<g>
	<rect x="776.3" y="210.6" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="767.4,199.1 779.4,167.1 791.4,199.1 	"/>
	<polygon fill="#3B7558" points="762,222.3 779.4,175.9 796.8,222.3 	"/>
</g>
<g>
	<rect x="150.2" y="1310.4" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="141.3,1298.9 153.3,1266.9 165.3,1298.9 	"/>
	<polygon fill="#3B7558" points="135.9,1322.1 153.3,1275.7 170.7,1322.1 	"/>
</g>
<g>
	<rect x="411.8" y="871.2" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="403,859.7 414.9,827.7 426.9,859.7 	"/>
	<polygon fill="#3B7558" points="397.5,882.9 414.9,836.5 432.3,882.9 	"/>
</g>
<g>
	<rect x="497.7" y="1683.5" fill="#B09F8E" width="7.7" height="30"/>
	<polygon fill="#3B7558" points="486.4,1668.8 501.7,1627.9 517,1668.8 	"/>
	<polygon fill="#3B7558" points="479.5,1698.5 501.7,1639.1 524,1698.5 	"/>
</g>
<g>
	<rect x="171.8" y="1457.6" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="162.9,1446.1 174.9,1414.1 186.9,1446.1 	"/>
	<polygon fill="#3B7558" points="157.5,1469.3 174.9,1422.8 192.3,1469.3 	"/>
</g>
<g>
	<rect x="852" y="1007.9" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="843.1,996.4 855.1,964.4 867.1,996.4 	"/>
	<polygon fill="#3B7558" points="837.7,1019.7 855.1,973.2 872.5,1019.7 	"/>
</g>
<path fill="#A9C4AB" d="M562.7,360.6c0.5-0.9,0.8-1.9,0.8-3c0-2.6-1.8-4.9-4.5-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.1c0,0.2,0,0.3,0,0.5L562.7,360.6L562.7,360.6z"/>
<g>
	<rect x="56.3" y="921.8" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="47.5,910.3 59.5,878.3 71.4,910.3 	"/>
	<polygon fill="#3B7558" points="42,933.5 59.5,887 76.8,933.5 	"/>
</g>
<path fill="#A9C4AB" d="M101.1,945.3c0.5-0.9,0.8-1.9,0.8-3c0-2.7-1.8-4.9-4.4-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.2c0,0.2,0,0.3,0,0.5H101.1L101.1,945.3z"/>
<path fill="#A9C4AB" d="M910.7,1026.7c0.5-0.9,0.8-1.9,0.8-3c0-2.7-1.8-4.9-4.5-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.2c0,0.2,0,0.3,0,0.5H910.7L910.7,1026.7z"/>
<path fill="#A9C4AB" d="M824.9,974c0.5-0.9,0.8-1.9,0.8-3c0-2.7-1.8-4.9-4.5-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.2c0,0.2,0,0.3,0,0.5H824.9L824.9,974z"/>
<path fill="#A9C4AB" d="M346.9,197.1c-0.5-0.9-0.8-1.9-0.8-3c0-2.6,1.8-4.9,4.5-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.1c0,0.2,0,0.3,0,0.5L346.9,197.1L346.9,197.1z"/>
<path fill="#A9C4AB" d="M407.6,1173.7c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.5-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L407.6,1173.7L407.6,1173.7z"/>
<path fill="#A9C4AB" d="M200.3,1472.9c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.4-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L200.3,1472.9L200.3,1472.9z"/>
<path fill="#A9C4AB" d="M775.3,1313.1c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.5-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L775.3,1313.1L775.3,1313.1z"/>
<path fill="#A9C4AB" d="M519.2,1315.7c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.5-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L519.2,1315.7L519.2,1315.7z"/>
<path fill="#A9C4AB" d="M45.3,1636.9c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.4-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L45.3,1636.9L45.3,1636.9z"/>
<g>
	<path fill="#4D4D4D" d="M78.2,337.9c0.6-1.5,1.5-2.9,2.9-4c1.3-1,3.1-1.7,4.8-1.6c1.8,0.1,3.3,1.1,4.6,2c1.3,1,2.4,2.1,3.5,3.3h-0.9
		c0.7-1.4,1.7-2.8,3.1-3.8c1.3-1,3.1-1.7,4.8-1.7s3.5,0.7,4.8,1.8c0.7,0.5,1.2,1.1,1.7,1.8c0.2,0.3,0.5,0.7,0.7,1l0.3,0.5l0.1,0.3
		l0.1,0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0.1s-0.1,0-0.1,0l0,0l0,0l0,0l0,0h-0.1l-0.1-0.1l-0.2-0.2l-0.4-0.4
		c-0.3-0.3-0.6-0.5-0.9-0.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.3-0.6-2.6-1-3.9-0.9c-1.3,0-2.6,0.5-3.8,1.2c-1.2,0.7-2.2,1.7-3.2,2.9
		l-0.6,0.7l-0.3-0.7c-0.7-1.4-1.6-2.6-2.9-3.4s-2.8-1-4.2-0.9c-1.4,0.1-2.7,0.5-3.9,1.2c-1.3,0.7-2.4,1.7-3.6,2.8L78.2,337.9z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M102.1,352.4c0.2-1.1,0.8-2.1,1.6-3c0.8-0.8,2.1-1.4,3.4-1.3c1.3,0.2,2.3,1,3.1,1.7c0.8,0.7,1.6,1.5,2.3,2.4
		h-1c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4s2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7c0.1,0.2,0.3,0.5,0.3,0.8
		l0.1,0.4l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1-0.1,0-0.1,0l0,0l0,0l0,0l0,0l0,0l-0.1-0.1l-0.2-0.1l-0.3-0.3
		c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9
		l-0.7,0.8l-0.3-0.8c-0.3-1-0.9-1.9-1.7-2.4c-0.8-0.6-1.9-0.6-2.7-0.5c-0.8,0.1-1.6,0.4-2.4,0.9s-1.5,1.1-2.4,1.8L102.1,352.4z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M114.2,329.4c0.4-1.4,1.2-2.7,2.3-3.8c1.1-1,2.7-1.8,4.4-1.7c1.7,0.2,3,1.1,4.1,2.1s2.1,2,3,3.2h-1
		c0.6-1.3,1.4-2.6,2.6-3.7c1.1-1,2.7-1.8,4.4-1.8s3.3,0.8,4.4,1.8c0.3,0.3,0.6,0.5,0.8,0.8s0.5,0.6,0.6,0.9c0.2,0.3,0.4,0.6,0.5,1
		l0.2,0.5l0.1,0.2l0.1,0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1-0.1,0-0.1,0l0,0l0,0l0,0l0,0h-0.1l-0.1-0.1l-0.2-0.2
		l-0.4-0.4c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.4-1-0.8-1.6-1.2c-1.1-0.6-2.2-1-3.4-0.9c-1.1,0.1-2.2,0.5-3.3,1.2
		c-1,0.7-1.9,1.7-2.8,2.8l-0.6,0.8l-0.3-0.8c-0.5-1.3-1.3-2.5-2.4-3.3s-2.5-1-3.7-0.9s-2.3,0.5-3.4,1.3
		C116.3,327.4,115.4,328.4,114.2,329.4L114.2,329.4z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M195.2,1169c0.6-1.5,1.5-2.9,2.9-3.9c1.3-1,3.1-1.7,4.8-1.6c1.8,0.2,3.3,1.1,4.6,2s2.4,2.1,3.5,3.3h-1
		c0.7-1.4,1.7-2.8,3.1-3.8c1.3-1,3.1-1.7,4.9-1.7c1.8,0,3.5,0.7,4.8,1.8c0.7,0.5,1.2,1.1,1.7,1.8c0.2,0.3,0.5,0.7,0.6,1l0.3,0.5
		l0.1,0.3l0.1,0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1-0.1-0.1,0.1c-0.2,0.1-0.1,0-0.1,0.1l0,0l0,0l0,0l0,0l-0.1-0.1l-0.1-0.1l-0.2-0.2
		l-0.4-0.4c-0.3-0.3-0.6-0.5-0.9-0.7c-0.6-0.4-1.2-0.9-1.8-1.2c-1.3-0.6-2.6-1-3.9-0.9c-1.3,0.1-2.6,0.4-3.8,1.2
		c-1.2,0.7-2.2,1.7-3.2,2.9l-0.6,0.7l-0.3-0.7c-0.7-1.4-1.6-2.6-2.9-3.4c-1.3-0.8-2.9-1-4.2-0.9c-1.4,0.1-2.7,0.5-3.9,1.2
		c-1.3,0.7-2.4,1.7-3.6,2.8L195.2,1169z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M219,1183.5c0.2-1.1,0.8-2.1,1.6-2.9s2.1-1.4,3.4-1.3c1.3,0.2,2.3,1,3.1,1.7s1.6,1.5,2.3,2.4h-1
		c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7
		c0.1,0.2,0.3,0.5,0.4,0.8l0.1,0.4l0.1,0.2v0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1-0.1-0.1,0.1c-0.2,0.1-0.1,0-0.1,0.1l0,0l0,0l0,0l0,0
		l0,0l-0.1-0.1l-0.1-0.1l-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6
		c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9l-0.7,0.8l-0.3-0.8c-0.4-0.9-0.9-1.8-1.7-2.4s-1.9-0.7-2.7-0.5
		s-1.6,0.4-2.4,0.9c-0.8,0.5-1.5,1.1-2.4,1.8L219,1183.5z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M231.1,1160.5c0.4-1.4,1.2-2.7,2.3-3.8c1.1-1,2.7-1.8,4.4-1.7c1.7,0.2,3,1.1,4.1,2.1c1.1,0.9,2.1,2.1,3,3.2
		h-1c0.6-1.3,1.4-2.7,2.6-3.7c1.1-1,2.7-1.8,4.4-1.8s3.3,0.8,4.4,1.8c0.3,0.3,0.6,0.5,0.8,0.8c0.2,0.3,0.5,0.6,0.6,0.9
		c0.2,0.3,0.4,0.6,0.5,1l0.2,0.5l0.1,0.2v0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1-0.1-0.1,0.1c-0.2,0.1,0,0-0.1,0.1l0,0l0,0l0,0l0,0v-0.1
		l-0.1-0.1l-0.2-0.2l-0.4-0.4c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.4-1-0.8-1.6-1.2c-1.1-0.6-2.2-1-3.4-0.9c-1.1,0.1-2.2,0.4-3.3,1.2
		c-1,0.7-1.9,1.7-2.8,2.8l-0.6,0.8l-0.3-0.8c-0.5-1.3-1.3-2.5-2.4-3.3c-1.1-0.8-2.5-1-3.7-0.9c-1.2,0.2-2.3,0.5-3.4,1.3
		C233.2,1158.6,232.3,1159.6,231.1,1160.5L231.1,1160.5z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M781.9,436.4c0.6-1.5,1.5-2.9,2.9-4c1.3-1,3.1-1.7,4.8-1.6c1.8,0.1,3.3,1.1,4.6,2c1.3,1,2.4,2.1,3.5,3.3h-0.9
		c0.7-1.4,1.7-2.8,3.1-3.8c1.3-1,3.1-1.7,4.8-1.7s3.5,0.7,4.8,1.8c0.7,0.5,1.2,1.1,1.7,1.8c0.2,0.3,0.5,0.7,0.7,1l0.3,0.5l0.1,0.3
		l0.1,0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0.1c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0h-0.1l-0.1-0.1l-0.2-0.2l-0.4-0.4
		c-0.3-0.3-0.6-0.5-0.9-0.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.3-0.6-2.6-1-3.9-0.9c-1.3,0-2.6,0.5-3.8,1.2s-2.2,1.7-3.2,2.9l-0.6,0.7
		l-0.3-0.7c-0.7-1.4-1.6-2.6-2.9-3.4c-1.3-0.8-2.8-1-4.2-0.9s-2.7,0.5-4,1.2s-2.4,1.7-3.6,2.8L781.9,436.4z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M805.7,450.9c0.2-1.1,0.8-2.1,1.6-3c0.8-0.8,2.1-1.4,3.4-1.3c1.3,0.2,2.3,1,3.1,1.7s1.5,1.5,2.3,2.4h-1
		c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7
		c0.1,0.2,0.3,0.5,0.3,0.8l0.1,0.4l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0
		l-0.1-0.1l-0.2-0.1l-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6
		c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9l-0.7,0.8l-0.3-0.8c-0.3-1-0.9-1.9-1.7-2.4c-0.8-0.6-1.9-0.6-2.7-0.5
		s-1.6,0.4-2.4,0.9c-0.8,0.5-1.5,1.1-2.4,1.8L805.7,450.9z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M850.2,484.7c0.2-1.1,0.8-2.1,1.6-3c0.8-0.8,2.1-1.4,3.4-1.3c1.3,0.2,2.3,1,3.1,1.7s1.5,1.5,2.3,2.4h-1
		c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7
		c0.1,0.2,0.3,0.5,0.3,0.8l0.1,0.4l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0
		l-0.1-0.1l-0.2-0.1l-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6
		c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9l-0.7,0.8l-0.3-0.8c-0.3-1-0.9-1.9-1.7-2.4c-0.8-0.6-1.9-0.6-2.7-0.5
		s-1.6,0.4-2.4,0.9c-0.8,0.5-1.5,1.1-2.4,1.8L850.2,484.7z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M817.8,427.9c0.4-1.4,1.2-2.7,2.3-3.8c1.1-1,2.7-1.8,4.4-1.7c1.7,0.2,3,1.1,4.1,2.1s2.1,2,3,3.2h-1
		c0.6-1.3,1.4-2.6,2.6-3.7c1.2-1,2.7-1.8,4.4-1.8s3.3,0.8,4.4,1.8c0.3,0.3,0.5,0.5,0.8,0.8c0.2,0.3,0.5,0.6,0.6,0.9
		c0.2,0.3,0.4,0.6,0.5,1l0.2,0.5l0.1,0.2v0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0l-0.1-0.1
		l-0.2-0.2l-0.4-0.4c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.4-1-0.8-1.6-1.2c-1.1-0.6-2.2-1-3.4-0.9c-1.1,0.1-2.2,0.5-3.3,1.2
		c-1,0.7-1.9,1.7-2.8,2.8L831,429l-0.3-0.8c-0.5-1.3-1.3-2.5-2.4-3.3c-1.1-0.8-2.5-1-3.7-0.9s-2.3,0.5-3.4,1.3
		c-1.1,0.7-2,1.7-3.1,2.7L817.8,427.9z"/>
</g>
<path fill="#635342" d="M381.2,265.3c0,0-13.3,19.1-13.2,18.8s2.7-10,2.7-10l6.3-6.7L381.2,265.3z"/>
<polygon fill="#635342" points="419.3,280.9 426.2,268.8 423.8,262.1 417.1,255.2 409,253 389.3,253 377,262.1 377,267.4 385.3,264.3 
	403.8,264.1 415.1,271.8 "/>
<path fill="none" stroke="#8C704C" strokeMiterlimit="10" strokeWidth="3" d="M368,284.1L368,284.1c0-11,8.9-20,20-20h11.4c11,0,20,8.9,20,20l0,0"/>
<path fill="none" stroke="#8C704C" strokeMiterlimit="10" strokeWidth="3" d="M375.5,268.5c1.7-9.3,9.9-16.3,19.6-16.3h11.4c11,0,20,8.9,20,20l0,0"/>
<g>
	<path fill="#7CB0C2" d="M767.5,504c-1.2-1.7-0.6-4,1-5.2c6.7-4.8,10.9-13.8,10.9-19.4c0-14.7-20.1-25.6-38.8-18.3
		c-18,7-0.7,20.3-33.7,20.1c-35.8-0.3-64.5,15.6-64.5,34.9c0,19.3,28.9,34.9,64.5,34.9s64.5-15.6,64.5-34.9
		C771.5,511.8,770.1,507.8,767.5,504z"/>
	<ellipse fill="#7CB0C2" cx="791.4" cy="551.1" rx="24.3" ry="14"/>
</g>
<g>
	<path fill="#2D5615" d="M726,458.8c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4c-0.5,1.9-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.9,1.4-5.7s1.4-3.7,2.3-5.4C723.7,462,724.7,460.3,726,458.8z"/>
</g>
<g>
	<path fill="#2D5615" d="M720.2,454.8c-1.3,4.8-2.3,9.7-3.1,14.6s-1.2,9.9-1.1,14.9h-1c0-5,0.7-10,1.6-15
		C717.5,464.4,718.7,459.5,720.2,454.8z"/>
</g>
<g>
	<path fill="#2D5615" d="M709.4,463.4c1.3,1.1,2.3,2.6,3.1,4.2s1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6l-0.3,2.6
		l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2s-0.7-3.4-1.4-5C711.5,466.1,710.7,464.6,709.4,463.4z"/>
</g>
<g>
	<path fill="#2D5615" d="M712.7,460c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.6,1.7,5.5s0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9L718,482l-1-0.1
		l0.3-2.8c0-1,0.1-1.9,0.1-2.9c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5C714.8,463.3,714,461.5,712.7,460z"/>
</g>
<g>
	<path fill="#2D5615" d="M692.7,529.8c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7c0.6-1.9,1.4-3.7,2.3-5.4C690.4,532.9,691.4,531.2,692.7,529.8z"/>
</g>
<g>
	<path fill="#2D5615" d="M684.8,524.2c-1.3,4.8-2.3,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.7-10,1.6-15
		C682.2,533.9,683.3,529,684.8,524.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M678.7,533.9c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2s-0.7-3.4-1.4-5C680.9,536.6,680,535.1,678.7,533.9z"/>
</g>
<g>
	<path fill="#2D5615" d="M682.1,530.5c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5C684.1,533.8,683.3,532,682.1,530.5z
		"/>
</g>
<g>
	<path fill="#2D5615" d="M703.6,529.3c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7c0.6-1.9,1.4-3.7,2.3-5.4C701.3,532.5,702.4,530.8,703.6,529.3z"/>
</g>
<g>
	<path fill="#2D5615" d="M697.4,524.2c-1.3,4.8-2.3,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.7-10,1.6-15
		C694.7,533.9,695.9,529,697.4,524.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M686.2,535.5c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2s-0.7-3.4-1.4-5C688.4,538.2,687.5,536.7,686.2,535.5z"
		/>
</g>
<g>
	<path fill="#2D5615" d="M695.2,533.5c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5C697.2,536.7,696.4,535,695.2,533.5z
		"/>
</g>
<g>
	<path fill="#2D5615" d="M704.7,537c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7c0.6-1.9,1.4-3.7,2.3-5.4C702.3,540.1,703.4,538.4,704.7,537z"/>
</g>
<g>
	<path fill="#2D5615" d="M692.7,529.8c-1.3,4.8-2.3,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.7-10,1.6-15
		C690,539.4,691.2,534.5,692.7,529.8z"/>
</g>
<g>
	<path fill="#2D5615" d="M679.7,537c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2s-0.7-3.4-1.4-5C681.8,539.7,680.9,538.2,679.7,537z"/>
</g>
<g>
	<path fill="#2D5615" d="M690.1,535.8c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5C692.1,539,691.3,537.3,690.1,535.8z
		"/>
</g>
<g>
	<path fill="#2D5615" d="M482.1,724.1c-2.2,2.8-3.9,5.9-5.5,9c-1.5,3.2-2.8,6.5-3.7,9.8c-0.9,3.4-1.7,6.8-2.1,10.3
		c-0.3,1.7-0.3,3.5-0.4,5.2s0,3.5,0,5.2h-2c0.1-1.8,0.1-3.6,0.3-5.4c0.2-1.8,0.3-3.6,0.7-5.3c0.5-3.5,1.5-7,2.6-10.4
		s2.6-6.6,4.2-9.8C477.9,729.8,479.9,726.8,482.1,724.1z"/>
</g>
<g>
	<path fill="#2D5615" d="M467.9,714.1c-1.2,4.4-2.2,8.8-3.2,13.2c-0.9,4.4-1.7,8.9-2.4,13.3c-0.7,4.5-1.2,9-1.5,13.4
		c-0.3,4.5-0.4,9-0.3,13.5h-2c0-4.6,0.3-9.1,0.8-13.7c0.5-4.5,1.2-9,2-13.5c0.9-4.5,1.8-8.9,2.9-13.3
		C465.3,722.8,466.5,718.4,467.9,714.1z"/>
</g>
<g>
	<path fill="#2D5615" d="M456.8,731.6c2.4,2.1,4.2,4.8,5.6,7.6c1.4,2.9,2.4,5.9,3,9.1l0.4,2.4c0.1,0.8,0.2,1.6,0.2,2.4
		c0.2,1.6,0.1,3.2,0.2,4.8c0,1.6-0.1,3.2-0.2,4.8l-0.6,4.8l-2-0.4l0.8-4.6c0.1-1.5,0.4-3.1,0.5-4.7c0-1.5,0.2-3.1,0-4.7
		c0-0.8,0-1.6-0.1-2.3l-0.3-2.3c-0.5-3.1-1.2-6.1-2.5-9C460.7,736.6,459.1,733.8,456.8,731.6z"/>
</g>
<g>
	<path fill="#2D5615" d="M462.9,725.5c1.2,1.3,2.2,2.7,3,4.2c0.9,1.5,1.6,3.1,2.4,4.7c1.4,3.2,2.3,6.6,3,10l0.5,2.6l0.3,2.6
		c0.3,1.7,0.3,3.5,0.4,5.2c0.1,1.8,0,3.5,0.1,5.3l-0.2,5.3l-2-0.2l0.5-5.2c0-1.7,0.2-3.4,0.2-5.2c0-1.7,0-3.5-0.2-5.2l-0.2-2.6
		l-0.4-2.6c-0.5-3.4-1.3-6.8-2.5-10c-0.6-1.6-1.3-3.2-2.1-4.8C464.9,728.3,464,726.8,462.9,725.5z"/>
</g>
<g>
	<path fill="#2D5615" d="M502,723.3c-2.2,2.8-3.9,5.9-5.5,9c-1.5,3.2-2.8,6.5-3.7,9.8c-0.9,3.4-1.7,6.8-2.1,10.3
		c-0.3,1.7-0.3,3.5-0.4,5.2s0,3.5,0,5.2h-2c0.1-1.8,0.1-3.6,0.3-5.4c0.2-1.8,0.3-3.6,0.7-5.3c0.5-3.5,1.5-7,2.6-10.4
		s2.6-6.6,4.2-9.8C497.8,729,499.7,726,502,723.3z"/>
</g>
<g>
	<path fill="#2D5615" d="M490.6,714.1c-1.2,4.4-2.2,8.8-3.2,13.2c-0.9,4.4-1.7,8.9-2.4,13.3c-0.7,4.5-1.2,9-1.5,13.4
		c-0.3,4.5-0.4,9-0.3,13.5h-2c0-4.6,0.3-9.1,0.8-13.7c0.5-4.5,1.2-9,2-13.5c0.9-4.5,1.8-8.9,2.9-13.3
		C488.1,722.8,489.2,718.4,490.6,714.1z"/>
</g>
<g>
	<path fill="#2D5615" d="M470.4,734.4c2.4,2.1,4.2,4.8,5.6,7.6c1.4,2.9,2.4,5.9,3,9.1l0.4,2.4c0.1,0.8,0.2,1.6,0.2,2.4
		c0.2,1.6,0.1,3.2,0.2,4.8c0,1.6-0.1,3.2-0.2,4.8l-0.6,4.8l-2-0.4l0.8-4.6c0.1-1.5,0.4-3.1,0.5-4.7c0-1.5,0.2-3.1,0-4.7
		c0-0.8,0-1.6-0.1-2.3l-0.3-2.3c-0.5-3.1-1.2-6.1-2.5-9C474.3,739.4,472.7,736.6,470.4,734.4z"/>
</g>
<g>
	<path fill="#2D5615" d="M486.6,730.9c1.2,1.3,2.2,2.7,3,4.2c0.9,1.5,1.6,3.1,2.4,4.7c1.4,3.2,2.3,6.6,3,10l0.5,2.6l0.3,2.6
		c0.3,1.7,0.3,3.5,0.4,5.2c0.1,1.8,0,3.5,0.1,5.3l-0.2,5.3l-2-0.2l0.5-5.2c0-1.7,0.2-3.4,0.2-5.2c0-1.7,0-3.5-0.2-5.2l-0.2-2.6
		l-0.4-2.6c-0.5-3.4-1.3-6.8-2.5-10c-0.6-1.6-1.3-3.2-2.1-4.8C488.7,733.7,487.8,732.2,486.6,730.9z"/>
</g>
<g>
	<path fill="#2D5615" d="M503.8,737.2c-2.2,2.8-3.9,5.9-5.5,9c-1.5,3.2-2.8,6.5-3.7,9.8c-0.9,3.4-1.7,6.8-2.1,10.3
		c-0.3,1.7-0.3,3.5-0.4,5.2s0,3.5,0,5.2h-2c0.1-1.8,0.1-3.6,0.3-5.4c0.2-1.8,0.3-3.6,0.7-5.3c0.5-3.5,1.5-7,2.6-10.4
		s2.6-6.6,4.2-9.8C499.6,742.8,501.5,739.8,503.8,737.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M482.2,724.1c-1.2,4.4-2.2,8.8-3.2,13.2c-0.9,4.4-1.7,8.9-2.4,13.3c-0.7,4.5-1.2,9-1.5,13.4
		c-0.3,4.5-0.4,9-0.3,13.5h-2c0-4.6,0.3-9.1,0.8-13.7c0.5-4.5,1.2-9,2-13.5c0.9-4.5,1.8-8.9,2.9-13.3
		C479.6,732.8,480.8,728.4,482.2,724.1z"/>
</g>
<g>
	<path fill="#2D5615" d="M458.5,737.2c2.4,2.1,4.2,4.8,5.6,7.6c1.4,2.9,2.4,5.9,3,9.1l0.4,2.4c0.1,0.8,0.2,1.6,0.2,2.4
		c0.2,1.6,0.1,3.2,0.2,4.8c0,1.6-0.1,3.2-0.2,4.8l-0.6,4.8l-2-0.4l0.8-4.6c0.1-1.5,0.4-3.1,0.5-4.7c0-1.5,0.2-3.1,0-4.7
		c0-0.8,0-1.6-0.1-2.3l-0.3-2.3c-0.5-3.1-1.2-6.1-2.5-9C462.4,742.1,460.8,739.4,458.5,737.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M477.4,735c1.2,1.3,2.2,2.7,3,4.2c0.9,1.5,1.6,3.1,2.4,4.7c1.4,3.2,2.3,6.6,3,10l0.5,2.6l0.3,2.6
		c0.3,1.7,0.3,3.5,0.4,5.2c0.1,1.8,0,3.5,0.1,5.3l-0.2,5.3l-2-0.2l0.5-5.2c0-1.7,0.2-3.4,0.2-5.2c0-1.7,0-3.5-0.2-5.2l-0.2-2.6
		l-0.4-2.6c-0.5-3.4-1.3-6.8-2.5-10c-0.6-1.6-1.3-3.2-2.1-4.8C479.4,737.9,478.5,736.4,477.4,735z"/>
</g>
<path fill="#FFFFFF" d="M750.2,518.9c0-0.1,0-0.2,0-0.3c0-3.1-3.5-5.7-7.9-5.7s-7.9,2.5-7.9,5.7c0,0.1,0,0.2,0,0.3H750.2z"/>
<path fill="#FFFFFF" d="M738.2,506.9c0,0-1.2-0.8-2.4-0.4c-1.1,0.4-1.8,1.3-1.5,2s1.4,1,2.5,0.5c0.5-0.2,0.9-0.5,1.2-0.8
	c0.5,0.7,0.7,1.7-0.2,3.1c-2,3-3.5,4.6-3.2,7.4h1.2c-0.2-2.8,1.6-4.1,3.2-7.4C740.4,508.4,738.2,506.9,738.2,506.9z"/>
<path fill="#333333" d="M734.3,509.1c0,0,0,1.3-0.5,2.1c1.1-0.3,1.9-1.8,1.9-1.8l-1.2-0.6C734.5,508.7,734,508.6,734.3,509.1z"/>
<g>
	<path fill="#A37C52" d="M324.3,1465.1c-4.3,1.5-6.9,3.4-11,4.8c-2.4-0.9-25.7-9-28-10.1c-0.5-0.2-0.6-1.6-0.5-2.4
		c0.1-1,0.5-2.1,1-2.9c1.2-2,2.4-2.3,4.2-1.6c4.5,1.7,9,3.2,13.5,4.8C303.8,1457.7,323.6,1464.8,324.3,1465.1z"/>
	<path fill="#A37C52" d="M322.5,1473.3c-4.6-1.6-14.9-5.5-19.6-7.1c2.4-0.8,10.2,0.7,12.3,0c1.2-0.4,2.5-0.7,3.7-1.3
		c2.2-1.2,4.2-0.6,6.4,0.5C322.9,1467.4,322,1470,322.5,1473.3z"/>
	<path fill="#A37C52" d="M284.6,1468.2c0.3-0.4,0.6-1.6,1.4-2c1-0.6,2,0.3,2.6,1.1c1.2,1.5,1.8,3.2,1.4,5.2c-0.4,1.8-1.7,2.3-3.2,1.1
		C285.4,1472.3,284.8,1470.6,284.6,1468.2z"/>
	<path fill="#B09F8E" d="M329.1,1468.2c-0.2,2.4-0.7,4-2,5.2c-1.5,1.4-3,0.9-3.4-1.1c-0.4-1.8,0.2-3.5,1.3-4.9
		c0.7-0.9,1.6-1.8,2.7-1.2C328.5,1466.6,328.8,1467.8,329.1,1468.2z"/>
	<path fill="#FFAD00" d="M305.7,1410.4c2,2.5,3.4,5,4.4,7.6c1.8,4.3,2.5,8.8,2.3,13.4c0,0.5,0.2,1,0.4,1.5c0.4-0.2,0.9-0.4,1.3-0.7
		c0.8-0.8,1.6-1.6,2.4-2.5c3.8,3.8,5.4,8.1,4.5,13.2c-0.8,4.5-3.3,7.8-7.4,10.3c0.2-0.9,0.3-1.5,0.4-2.2c0.2-2.2-0.4-4.1-1.9-5.7
		c-2.2-2.3-3.5-5.1-3.9-8.3c0-0.4-0.3-0.8-0.5-1.2c-0.4,0.2-0.8,0.4-1.1,0.7c-1.9,2.4-2.9,5.3-3.1,8.5c-0.9-0.8-1.4-0.3-2,0.3
		c-1.7,1.9-2.2,4.2-1.6,6.7c0,0.2,0.1,0.5,0.2,0.7c0,0,0,0.1-0.1,0.2c-2.5-1.3-4.4-3.2-5.7-5.6c-3-5.4-2.4-12.1,1.9-16.6
		C301.5,1425,304.8,1418.4,305.7,1410.4z"/>
	<path fill="#B09F8E" d="M291.2,1473.1c0.5-3.1-0.4-5.7-2.8-7.9c2-0.7,3.9-1.4,5.7-2c10-3.5,20-7,30.1-10.5c1.5-0.5,2.3-0.3,3.3,0.9
		c1.3,1.6,2,3.5,1.3,5.5c-0.2,0.6-0.8,1.2-1.3,1.4c-12,4.2-24,8.4-36,12.6C291.5,1473.2,291.4,1473.1,291.2,1473.1z"/>
</g>
<circle fill="#005ADA" cx="689" cy="1559.3" r="36.7"/>
<rect x="596.1" y="1619.7" fill="none" width="318.2" height="130"/>
<text transform="matrix(1 0 0 1 576.1337 1634.5964)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2023</text>
<text transform="matrix(1 0 0 1 576.1337 1652.5964)" font-family="Lato" fontSize="18">Helped clients manage social media at </text>
<text transform="matrix(1 0 0 1 576.1337 1670.5964)" font-family="Lato" fontSize="18">in-person events.</text>
<text transform="matrix(1 0 0 1 576.1337 1700.5964)" font-family="Lato" fontSize="18">Provided trainings for clients on </text>
<text transform="matrix(1 0 0 1 576.1337 1718.5964)" font-family="Lato" fontSize="18">employee social engagement platforms.</text>
<path fill="#4D4D4D" d="M524.4,1612.6c3.8-4.4,10-6.2,12.6,0.2l0.3,0.8l0.6-0.8c3.4-4.6,7.6-5.4,11.8-1.1c0.2,0.2,0.5,0.5,0.8,0.7l0,0
	c0,0-0.1,0.1,0.1-0.1c0.2-0.1,0.1,0,0.1-0.1c-0.9-2.9-3.4-5.5-6.7-5.6c-3.3,0-5.8,2.7-7,5.5h1c-2.8-3.6-7.1-7.6-11.5-3.6
	c-1.1,1.1-1.9,2.4-2.3,3.8L524.4,1612.6L524.4,1612.6z"/>
<rect x="675" y="1373" fill="none" width="191.7" height="161"/>
<text transform="matrix(1 0 0 1 675 1386.2744)" font-family="Lato" fontSize="18">Multiple clients invest</text>
<text transform="matrix(1 0 0 1 675 1407.8744)" font-family="Lato" fontSize="18">in executive social </text>
<text transform="matrix(1 0 0 1 675 1429.4744)" font-family="Lato" fontSize="18">media efforts.</text>
<circle fill="#FFAD00" cx="372.6" cy="1235.7" r="36.7"/>
<circle fill="#005ADA" cx="689" cy="1559.3" r="66"/>
<circle fill="#828487" cx="527.9" cy="911.8" r="14"/>
<circle fill="#828487" cx="325" cy="142.1" r="14"/>
<circle fill="#828487" cx="271" cy="401.1" r="14"/>
<circle fill="#828487" cx="640" cy="638.1" r="14"/>
<circle fill="#828487" cx="285" cy="638.1" r="14"/>
<circle fill="#FFAD00" cx="373.5" cy="1559.3" r="66"/>
<circle fill="#828487" cx="897.4" cy="1397.5" r="14"/>
<circle fill="#828487" cx="73.9" cy="1076.7" r="14"/>
</Svg>
);