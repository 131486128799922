import React, { FC } from 'react';

import { Svg } from '@components/core';

interface IOwnProps extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
}

export const AudiencePersonas: FC<IOwnProps> = ({ className = '' }) => (
  <Svg
    aria-labelledby="AudiencePersonasTitle"
    className={className}
    role="img"
    viewBox="0 0 152 152"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="AudiencePersonasTitle">AudiencePersonas Icon</title>
    <circle cx="76" cy="76" fill="#2a7de1" r="76" />
    <circle cx="75.13" cy="76.11" fill="#fff" r="64" />
    <path
      d="M135.06 98.61c-4.36 11.61-11.99 21.61-21.77 28.88-8.6-.7-16.34-3.38-22.33-7.38-.02-.24-.02-.47-.02-.7 0-14.89 12.08-26.96 26.97-26.96 6.51 0 12.48 2.31 17.15 6.16zM130.45 71.92c0-.84-.3-1.6-.78-2.18.03-.48.05-.97.05-1.44 0-8.39-5.3-11.57-11.83-11.57s-11.83 3.18-11.83 11.57c0 .47.02.95.05 1.44a3.41 3.41 0 0 0-.78 2.18c0 1.29.69 2.39 1.67 2.92 1.81 6.44 6 12.26 10.88 12.26 4.88 0 9.07-5.82 10.88-12.26 1.01-.53 1.69-1.63 1.69-2.92z"
      fill="#ffa700"
    />
    <g>
      <path
        d="M59.32 119.41c0 .23 0 .47-.02.71-5.99 3.99-13.73 6.67-22.33 7.37-9.78-7.27-17.41-17.27-21.77-28.87a26.897 26.897 0 0 1 17.16-6.17c14.89 0 26.96 12.07 26.96 26.96zM44.91 71.92c0-.84-.3-1.6-.78-2.18.03-.48.05-.97.05-1.44 0-8.39-5.3-11.57-11.83-11.57S20.53 59.91 20.53 68.3c0 .47.02.95.05 1.44a3.41 3.41 0 0 0-.78 2.18c0 1.29.69 2.39 1.67 2.92 1.81 6.44 6 12.26 10.88 12.26s9.07-5.82 10.88-12.26c1-.53 1.68-1.63 1.68-2.92z"
        fill="#ffa700"
      />
    </g>
    <g>
      <path
        d="M115.78 125.54c-.81.67-1.64 1.32-2.49 1.95-10.65 7.93-23.86 12.62-38.16 12.62s-27.51-4.69-38.16-12.62c-.85-.63-1.68-1.28-2.49-1.95 2.43-20.3 19.71-36.03 40.66-36.03 20.94 0 38.22 15.73 40.64 36.03zM94.19 58.33c0-1.28-.45-2.43-1.18-3.31.05-.74.08-1.47.08-2.18 0-12.74-8.04-17.56-17.96-17.56-9.92 0-17.96 4.83-17.96 17.56 0 .72.03 1.45.08 2.18-.72.88-1.18 2.03-1.18 3.31 0 1.96 1.04 3.63 2.54 4.44 2.74 9.78 9.11 18.62 16.52 18.62 7.42 0 13.78-8.84 16.52-18.62 1.5-.81 2.54-2.48 2.54-4.44z"
        fill="#003575"
      />
    </g>
  </Svg>
);
