import React, { FC } from 'react';

import { Svg } from '@components/core';

interface IOwnProps extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
}

export const BrandGlueLogo: FC<IOwnProps> = ({ className = '' }) => (
  <Svg
    aria-labelledby="brandglueLogoTitle"
    className={className}
    height="80"
    role="img"
    viewBox="0 0 213 59"
    width="220"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="brandglueLogoTitle">BrandGlue Logo</title>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g fillRule="nonzero" id="brandglue-logo">
        <path
          d="M117.39,42.43 C118.01,41.33 118.84,40.54 119.65,39.97 C118.87,39.89 118.1,39.81 117.32,39.74 C98.21,37.89 78.99,37.02 59.75,37.34 C50.13,37.51 40.51,37.98 30.91,38.82 C21.31,39.67 11.73,40.89 2.21,42.6 C2.19,42.6 2.16,42.61 2.15,42.61 C0.7,42.91 -0.23,44.32 0.06,45.77 C0.36,47.22 1.77,48.15 3.22,47.86 L3.23,47.86 C12.52,45.96 21.92,44.54 31.38,43.48 C40.84,42.42 50.34,41.74 59.87,41.35 C78.92,40.58 98.05,41.01 117.1,42.4 C117.19,42.42 117.29,42.43 117.39,42.43 Z"
          fill="#FFA700"
          id="Shape"
        />
        <path
          d="M132.26,43.74 C146.22,45.15 160.14,47.09 173.97,49.57 C186.98,51.91 199.92,54.71 212.72,58.06 C213.07,58.09 213.1,57.74 212.72,57.63 C200.02,54 187.17,50.89 174.22,48.26 C159.57,45.28 144.8,42.91 129.95,41.12 C129.65,41.08 129.35,41.05 129.05,41.01 C128.75,40.97 128.45,40.94 128.15,40.9 C127.09,40.78 126.03,40.65 124.97,40.53 C124.44,41.12 124.01,41.9 124.01,42.86 C124.01,42.89 124.01,42.92 124.01,42.95 C125.39,43.07 126.77,43.2 128.15,43.32 C128.69,43.37 129.23,43.42 129.77,43.48 C130.6,43.58 131.43,43.66 132.26,43.74 Z"
          fill="#FFA700"
          id="Shape"
        />
        <g fill="#005AD4" id="Group" transform="translate(6.000000, 0.000000)">
          <path
            d="M154.06,30.39 C154.06,34.25 154.54,37.56 161.37,37.56 C164.27,37.56 166.75,35.77 168.61,34.11 C168.68,36.45 169.99,37.42 172.19,37.42 C174.74,37.42 176.81,36.66 178.46,35.77 L178.05,33.22 L174.95,33.22 L174.68,25.91 L174.68,16.54 C174.68,14.82 173.51,14.06 171.78,14.06 C169.78,14.06 167.71,14.61 165.3,15.16 L165.3,17.78 L168.47,17.78 L168.47,25.84 L168.33,31.56 C166.95,32.11 164.47,33.01 162.75,33.01 C160.82,33.01 160.34,31.7 160.34,29.36 L160.27,25.84 L160.27,16.53 C160.27,14.81 159.24,14.05 157.44,14.05 C155.44,14.05 153.03,14.6 150.62,15.08 L150.62,17.77 L154.07,17.77 L154.06,30.39 Z"
            id="Shape"
          />
          <path
            d="M190.79,37.49 C193.48,37.49 196.86,36.73 199.48,35.28 L198.38,31.9 C195.69,32.52 193.35,32.59 191.83,32.59 C188.04,32.59 185.28,31.01 185,26.18 L199.89,26.18 L199.89,24.32 C199.89,18.32 197.27,14.12 190.03,14.12 C183,14.12 178.59,20.32 178.59,26.39 C178.59,35.56 183.21,37.49 190.79,37.49 Z M189.69,17.5 C192.1,17.5 193.34,19.57 193.34,23.29 L184.86,23.29 C184.93,19.98 186.45,17.5 189.69,17.5 Z"
            id="Shape"
          />
          <path
            d="M92.71,27.29 L92.57,20.26 L92.57,15.85 C92.57,11.58 92.36,8.4 85.06,8.4 C81.41,8.4 78.99,10.06 77.41,11.23 L77.41,9.93 C77.41,8.9 77,8.48 75.83,8.48 C73.83,8.48 70.38,8.83 67.9,9.31 L67.9,12.2 L71.35,12.2 L71.35,20.26 L71.21,26.74 C71.21,27.7 70.93,28.26 70.18,28.53 L68.27,29.19 L68.04,27.64 L64.87,27.64 L64.6,20.26 L64.6,15.02 C64.6,8.82 58.67,8.47 55.09,8.47 C52.54,8.47 49.78,8.82 46.89,9.85 L47.99,14.26 C50.54,13.71 52.54,13.43 53.99,13.43 C57.78,13.43 58.4,14.12 58.4,16.12 L58.4,18.88 C57.5,18.95 55.92,19.15 54.34,19.36 C49.93,19.98 45.52,21.63 45.52,26.74 C45.52,30.26 47.93,31.91 51.52,31.91 C54.62,31.91 57.24,29.7 58.55,28.33 C58.76,30.95 59.72,31.91 62.76,31.91 C64.37,31.91 66.69,30.86 68.21,29.6 C68.23,30.28 68.28,31.04 68.34,31.56 L80.3,31.56 C80.51,30.8 80.58,30.25 80.58,29.08 L78.3,28.32 C77.68,28.11 77.68,27.42 77.68,26.6 L77.54,20.26 L77.54,13.85 C78.71,13.3 81.05,12.89 82.99,12.89 C86.23,12.89 86.37,15.03 86.37,17.78 L86.37,20.26 L86.3,26.81 C86.3,27.5 86.09,28.12 85.4,28.46 L84.02,29.15 C83.95,30.11 84.02,30.73 84.16,31.56 L95.53,31.56 C95.81,30.94 95.88,30.39 95.88,29.15 L93.26,28.46 C92.85,28.33 92.71,27.98 92.71,27.29 Z M58.38,26.26 C57.48,26.54 55.56,27.22 54.04,27.22 C52.45,27.22 51.7,26.39 51.7,25.22 C51.7,23.36 53.22,22.6 55.56,22.19 C56.59,21.98 57.9,21.92 58.38,21.85 L58.38,26.26 Z"
            id="Shape"
          />
          <path
            d="M125.71,46.8 C120.32,46.8 118.08,45.15 118.02,42.97 C118.02,42.94 118.02,42.91 118.02,42.88 C118.02,42.52 118.08,42.18 118.18,41.88 C118.35,41.37 118.65,40.92 118.98,40.55 C119.04,40.48 119.11,40.41 119.17,40.34 L127.4,40.34 C129.58,40.34 130.69,40.93 131,42.02 C133.23,42.32 135.45,42.63 137.67,42.95 C137.8,42.41 137.87,41.85 137.87,41.26 C137.87,36.57 135.64,34.72 127.33,34.72 L118.94,34.72 L118.94,30.9 C119.1,30.83 119.25,30.76 119.4,30.69 C120.91,31.13 122.58,31.33 124.33,31.33 C130.64,31.33 135.1,28.1 135.1,22.87 C135.1,21.56 134.87,20.48 134.64,19.79 L139.33,19.79 L139.33,14.94 L132.1,16.63 C130.33,15.4 127.56,14.63 124.18,14.63 C121.4,14.63 119.16,15.29 117.46,16.39 L117.46,2.21 C117.46,0.35 116.84,0 115.12,0 C113.12,0 109.95,0.35 107.54,0.83 L107.54,3.73 L111.26,3.73 L111.26,8.97 C110.3,8.9 109.4,8.83 107.95,8.83 C101.27,8.83 96.03,13.58 96.03,21.17 C96.03,30.13 100.65,31.85 105.2,31.85 C108.03,31.85 109.89,29.85 111.13,28.47 C111.13,30.06 111.82,31.92 113.96,31.92 C114.54,31.92 115.06,31.88 115.56,31.82 C114.53,33.67 113.8,35.57 113.8,36.96 C113.8,39.27 115.42,40.04 116.57,40.04 C114.72,40.81 112.57,42.81 112.57,45.2 C112.57,49.9 117.8,50.97 122.88,50.97 C128.35,50.97 133.34,49.25 135.96,46.24 C134.33,46.05 131.73,45.76 129.9,45.55 C128.99,46.33 127.61,46.8 125.71,46.8 Z M124.17,18.18 C126.78,18.18 128.09,20.26 128.09,23.1 C128.09,26.02 126.86,27.95 124.09,27.95 C121.01,27.95 120.01,25.87 120.01,23.33 C120.01,20.64 120.93,18.18 124.17,18.18 Z M111.25,19.92 L111.04,26.19 C110.01,26.54 108.83,26.95 107.39,26.95 C104.7,26.95 102.56,24.81 102.56,20.89 C102.56,15.72 103.66,12.07 107.8,12.07 C109.31,12.07 110.35,12.28 111.25,12.48 C111.25,12.48 111.25,19.92 111.25,19.92 Z"
            id="Shape"
          />
          <path
            d="M40.46,13.78 C41.56,13.78 42.94,14.05 44.39,14.88 L46.25,9.57 C45.21,8.95 43.84,8.47 41.91,8.47 C39.29,8.47 37.15,10.13 36.05,11.64 L36.05,10.06 C36.05,8.75 35.02,8.4 34.12,8.4 C31.71,8.4 28.54,8.88 26.4,9.3 L26.4,12.19 L29.98,12.19 L29.98,20.19 L29.91,26.39 C29.84,27.91 29.36,28.18 28.26,28.53 L26.47,29.15 C26.47,29.77 26.33,30.05 26.68,31.56 L41.77,31.56 C41.98,30.94 42.05,29.77 42.05,28.8 L36.88,27.7 C36.47,27.56 36.33,27.42 36.33,26.67 L36.19,20.19 L36.19,14.75 C36.95,14.27 38.47,13.78 40.46,13.78 Z"
            id="Shape"
          />
          <path
            d="M15.93,8.62 C14.48,8.62 12.55,9.31 11.86,9.65 L12.41,12.68 C13.03,12.61 13.86,12.54 14.55,12.54 C18.27,12.54 19.44,15.43 19.44,19.78 C19.44,24.26 17.65,27.78 13.72,27.78 C12.34,27.78 11.58,27.64 10.89,27.57 L10.68,19.92 L10.68,2.21 C10.68,0.42 10.27,0 8.54,0 C6.13,0 3.37,0.48 0.82,1.03 L0.82,3.72 L4.54,3.72 L4.54,19.92 L4.33,30.74 C6.33,31.29 8.47,31.84 13.64,31.84 C20.81,31.84 26.05,26.67 26.05,19.5 C26.06,10.96 22.54,8.62 15.93,8.62 Z"
            id="Shape"
          />
          <path
            d="M141.24,25.84 L141.1,32.39 C141.1,33.36 140.89,33.91 140.07,34.11 L137.66,34.8 C137.66,35.83 137.59,36.25 137.8,37.14 L150.9,37.14 C151.11,36.38 151.17,35.83 151.17,34.66 L148.21,33.9 C147.66,33.76 147.59,33.07 147.59,32.25 L147.52,25.84 L147.52,7.44 C147.52,5.92 146.76,5.58 145.45,5.58 C143.45,5.58 139.66,5.92 137.66,6.41 L137.66,9.3 L141.24,9.3 L141.24,25.84 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </Svg>
);
