import React, { FC } from 'react';

import { Svg } from '@components/core';

interface IOwnProps extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
}

export const AboutBrandGlueMobile: FC<IOwnProps> = ({ className = '' }) => (
  <Svg
    aria-labelledby="brandglueLogoTitle"
    className={className}
    role="img"
    viewBox="0 0 640 1765.1"
    xmlns="http://www.w3.org/2000/svg"
  >
<rect x="127.4" y="106.1" fill="none" width="306.5" height="100"/>
<text transform="matrix(1 0 0 1 127.4003 120.9696)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2009</text>
<text transform="matrix(1 0 0 1 127.4003 138.9696)" font-family="Lato" fontSize="18">BrandGlue is born!</text>
<text transform="matrix(1 0 0 1 127.4003 164.9696)" font-family="Lato" fontSize="18">Started in a college dorm room,  </text>
<text transform="matrix(1 0 0 1 127.4003 182.9696)" font-family="Lato" fontSize="18">focused solely on Facebook marketing</text>
<rect x="127.4" y="240.7" fill="none" width="269.3" height="100"/>
<text transform="matrix(1 0 0 1 127.4004 255.595)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2011</text>
<text transform="matrix(1 0 0 1 127.4004 273.595)" font-family="Lato" fontSize="18">Helped the Washington Football </text>
<text transform="matrix(1 0 0 1 127.4004 291.595)" font-family="Lato" fontSize="18">Club become the fastest growing </text>
<text transform="matrix(1 0 0 1 127.4004 309.595)" font-family="Lato" fontSize="18">NFL team on Facebook</text>
<rect x="132" y="375.4" fill="none" width="228" height="100"/>
<text transform="matrix(1 0 0 1 132 390.2207)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2012</text>
<text transform="matrix(1 0 0 1 132 408.2207)" font-family="Lato" fontSize="18">Helped YouTube become  </text>
<text transform="matrix(1 0 0 1 132 426.2207)" font-family="Lato" fontSize="18">the fastest growing fan page </text>
<text transform="matrix(1 0 0 1 132 444.2207)" font-family="Lato" fontSize="18">on all of Facebook</text>
<rect x="132" y="510" fill="none" width="228" height="100"/>
<text transform="matrix(1 0 0 1 131.9997 524.8458)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2013</text>
<text transform="matrix(1 0 0 1 131.9997 542.8458)" font-family="Lato" fontSize="18">Team grows to 6 people,  </text>
<text transform="matrix(1 0 0 1 131.9997 560.8458)" font-family="Lato" fontSize="18">4 cats, 1 dog, 1 horse, </text>
<text transform="matrix(1 0 0 1 131.9997 578.8458)" font-family="Lato" fontSize="18">expands to Instagram </text>
<text transform="matrix(1 0 0 1 131.9997 596.8458)" font-family="Lato" fontSize="18">marketing</text>
<rect x="132" y="644.6" fill="none" width="165.1" height="100"/>
<text transform="matrix(1 0 0 1 131.9999 659.4714)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2015</text>
<text transform="matrix(1 0 0 1 131.9999 677.4714)" font-family="Lato" fontSize="18">Helped grow </text>
<text transform="matrix(1 0 0 1 131.9999 695.4714)" font-family="Lato" fontSize="18">Eloqua&apos;s Facebook </text>
<text transform="matrix(1 0 0 1 131.9999 713.4714)" font-family="Lato" fontSize="18">community by </text>
<text transform="matrix(1 0 0 1 131.9999 731.4714)" font-family="Lato" fontSize="18">2,500%</text>
<rect x="132" y="793.7" fill="none" width="189.1" height="100"/>
<text transform="matrix(1 0 0 1 132 808.5486)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2018</text>
<text transform="matrix(1 0 0 1 132 826.5486)" font-family="Lato" fontSize="18">Team grows to 10 </text>
<text transform="matrix(1 0 0 1 132 844.5486)" font-family="Lato" fontSize="18">people, 4 cats, 3 dog,  </text>
<text transform="matrix(1 0 0 1 132 862.5486)" font-family="Lato" fontSize="18">1 horse, 2 babies</text>
<rect x="132" y="1055.2" fill="none" width="279.6" height="78.8"/>
<text transform="matrix(1 0 0 1 131.9999 1070.1022)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2020</text>
<text transform="matrix(1 0 0 1 131.9999 1088.1022)" font-family="Lato" fontSize="18">Team helps all clients survive and </text>
<text transform="matrix(1 0 0 1 131.9999 1106.1022)" font-family="Lato" fontSize="18">thrive with social media efforts </text>
<text transform="matrix(1 0 0 1 131.9999 1124.1022)" font-family="Lato" fontSize="18">during the Coronavirus pandemic.</text>
<rect x="132" y="1312.3" fill="none" width="279.6" height="100"/>
<text transform="matrix(1 0 0 1 131.9999 1327.1702)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2022</text>
<text transform="matrix(1 0 0 1 131.9999 1345.1702)" enable-background="new"><tspan x="0" y="0" font-family="Lato" fontSize="18">Multiple clients invest in</tspan><tspan x="0" y="21.6" font-family="Lato" fontSize="18">executive social media efforts.</tspan></text>
<g>
	<rect x="418.2" y="411.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="409.4,399.6 421.4,367.6 433.3,399.6 	"/>
	<polygon fill="#3B7558" points="404,422.9 421.4,376.4 438.8,422.9 	"/>
</g>
<g>
	<rect x="473.9" y="109.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="465.1,97.6 477.1,65.6 489,97.6 	"/>
	<polygon fill="#3B7558" points="459.7,120.8 477.1,74.4 494.5,120.8 	"/>
</g>
<g>
	<rect x="491.3" y="109.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="482.5,125.1 494.5,93.1 506.5,125.1 	"/>
</g>
<g>
	<rect x="479.6" y="860.5" fill="#B09F8E" width="9.1" height="35.5"/>
	<polygon fill="#3B7558" points="466.3,843.2 484.3,794.9 502.4,843.2 	"/>
	<polygon fill="#3B7558" points="458.1,878.2 484.3,808.1 510.6,878.2 	"/>
</g>
<path fill="#A9C4AB" d="M463,434.6c0.5-0.9,0.8-1.9,0.8-3c0-2.6-1.8-4.9-4.5-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.1c0,0.2,0,0.3,0,0.5L463,434.6L463,434.6z"/>
<g>
	<rect x="455.6" y="1163" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="446.7,1151.5 458.7,1119.5 470.7,1151.5 	"/>
	<polygon fill="#3B7558" points="441.3,1174.7 458.7,1128.2 476.1,1174.7 	"/>
</g>
<g>
	<rect x="476.3" y="1444.1" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="467.4,1432.6 479.4,1400.6 491.4,1432.6 	"/>
	<polygon fill="#3B7558" points="462,1455.9 479.4,1409.4 496.8,1455.9 	"/>
</g>
<path fill="#A9C4AB" d="M500.4,1186.5c0.5-0.9,0.8-1.9,0.8-3c0-2.7-1.8-4.9-4.5-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.2c0,0.2,0,0.3,0,0.5L500.4,1186.5L500.4,1186.5z"/>
<g>
	<rect x="575.2" y="1310.5" fill="#B09F8E" width="6" height="23.5"/>
	<polygon fill="#3B7558" points="590.1,1299 578.1,1267 566.1,1299 	"/>
	<polygon fill="#3B7558" points="595.5,1322.2 578.1,1275.8 560.7,1322.2 	"/>
</g>
<path fill="#A9C4AB" d="M536.4,1334c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.5-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L536.4,1334L536.4,1334z"/>
<path fill="#A9C4AB" d="M509.2,1519.3c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.4-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.2c0,0.2,0,0.3,0,0.5L509.2,1519.3L509.2,1519.3z"/>
<path fill="#A9C4AB" d="M553.1,1022.4c0.5-0.9,0.8-1.9,0.8-3c0-2.7-1.8-4.9-4.5-6c-1.1-2.9-4.4-5-8.2-5c-4.8,0-8.6,3.2-8.6,7.2
	c0,0.7,0.1,1.4,0.4,2.1c-2.4,0.4-4.2,2.1-4.2,4.2c0,0.2,0,0.3,0,0.5H553.1L553.1,1022.4z"/>
<path fill="#A9C4AB" d="M511.6,1001.3c1.1-1.8,1.7-3.9,1.7-6.2c0-5.4-3.7-10.1-9.1-12.4c-2.3-5.9-9-10.2-16.8-10.2
	c-9.8,0-17.7,6.7-17.7,14.9c0,1.5,0.3,3,0.8,4.3c-5,0.9-8.7,4.3-8.7,8.5c0,0.3,0,0.7,0.1,1L511.6,1001.3L511.6,1001.3z"/>
<path fill="#A9C4AB" d="M545.4,1165.8c1.1-1.8,1.7-3.9,1.7-6.2c0-5.4-3.7-10.1-9.1-12.4c-2.3-5.9-9-10.2-16.8-10.2
	c-9.8,0-17.7,6.7-17.7,14.9c0,1.5,0.3,3,0.8,4.3c-5,0.9-8.7,4.3-8.7,8.5c0,0.3,0,0.7,0.1,1L545.4,1165.8L545.4,1165.8z"/>
<path fill="#A9C4AB" d="M549,175.1c-0.5-0.9-0.8-1.9-0.8-3c0-2.6,1.8-4.9,4.5-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
	c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.1c0,0.2,0,0.3,0,0.5L549,175.1L549,175.1z"/>
<g>
	<path fill="#4D4D4D" d="M529.5,289.6c0.6-1.5,1.5-2.9,2.9-4c1.3-1,3.1-1.7,4.8-1.6c1.8,0.1,3.3,1.1,4.6,2c1.3,1,2.4,2.1,3.5,3.3h-0.9
		c0.7-1.4,1.7-2.8,3.1-3.8c1.3-1,3.1-1.7,4.8-1.7s3.5,0.7,4.8,1.8c0.7,0.5,1.2,1.1,1.7,1.8c0.2,0.3,0.5,0.7,0.7,1l0.3,0.5l0.1,0.3
		l0.1,0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0.1c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0h-0.1l-0.1-0.1l-0.2-0.2l-0.4-0.4
		c-0.3-0.3-0.6-0.5-0.9-0.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.3-0.6-2.6-1-3.9-0.9c-1.3,0-2.6,0.5-3.8,1.2s-2.2,1.7-3.2,2.9l-0.6,0.7
		l-0.3-0.7c-0.7-1.4-1.6-2.6-2.9-3.4c-1.3-0.8-2.8-1-4.2-0.9s-2.7,0.5-4,1.2s-2.4,1.7-3.6,2.8L529.5,289.6z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M553.3,304c0.2-1.1,0.8-2.1,1.6-3c0.8-0.8,2.1-1.4,3.4-1.3c1.3,0.1,2.3,1,3.1,1.7s1.5,1.5,2.3,2.4h-1
		c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7
		c0.1,0.2,0.3,0.5,0.3,0.8l0.1,0.4l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0
		l-0.1-0.1l-0.2-0.1l-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6
		c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9l-0.7,0.8l-0.3-0.8c-0.3-1-0.9-1.9-1.7-2.4c-0.8-0.6-1.9-0.6-2.7-0.5
		s-1.6,0.4-2.4,0.9c-0.8,0.5-1.5,1.1-2.4,1.8L553.3,304z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M565.5,281.1c0.4-1.4,1.2-2.7,2.3-3.8c1.1-1,2.7-1.8,4.4-1.7c1.7,0.2,3,1.1,4.1,2.1s2.1,2,3,3.2h-1
		c0.6-1.3,1.4-2.6,2.6-3.7c1.2-1,2.7-1.8,4.4-1.8s3.3,0.8,4.4,1.8c0.3,0.3,0.5,0.5,0.8,0.8c0.2,0.3,0.5,0.6,0.6,0.9
		c0.2,0.3,0.4,0.6,0.5,1l0.2,0.5l0.1,0.2v0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0l-0.1-0.1
		l-0.2-0.2l-0.4-0.4c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.4-1-0.8-1.6-1.2c-1.1-0.6-2.2-1-3.4-0.9c-1.1,0.1-2.2,0.5-3.3,1.2
		c-1,0.7-1.9,1.7-2.8,2.8l-0.6,0.8l-0.3-0.8c-0.5-1.3-1.3-2.5-2.4-3.3c-1.1-0.8-2.5-1-3.7-0.9s-2.3,0.5-3.4,1.3
		c-1.1,0.7-2,1.7-3.1,2.7H565.5z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M511.1,776.1c0.6-1.5,1.5-2.9,2.9-4c1.3-1,3.1-1.7,4.8-1.6c1.8,0.2,3.3,1.1,4.6,2c1.3,1,2.4,2.1,3.5,3.3H526
		c0.7-1.4,1.7-2.8,3.1-3.8c1.3-1,3.1-1.7,4.8-1.7s3.5,0.7,4.8,1.8c0.7,0.5,1.2,1.1,1.7,1.8c0.2,0.3,0.5,0.7,0.7,1l0.3,0.5l0.1,0.3
		l0.1,0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0.1c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0h-0.1l-0.1-0.1l-0.2-0.2l-0.4-0.4
		c-0.3-0.3-0.6-0.5-0.9-0.7c-0.6-0.5-1.2-0.9-1.8-1.2c-1.3-0.6-2.6-1-3.9-0.9c-1.3,0-2.6,0.5-3.8,1.2s-2.2,1.7-3.2,2.9l-0.6,0.7
		l-0.3-0.7c-0.7-1.4-1.6-2.6-2.9-3.4c-1.3-0.8-2.8-1-4.2-0.9s-2.7,0.5-4,1.2s-2.4,1.7-3.6,2.8L511.1,776.1z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M535,790.6c0.2-1.1,0.8-2.1,1.6-3c0.8-0.8,2.1-1.4,3.4-1.3c1.3,0.2,2.3,1,3.1,1.7s1.5,1.5,2.3,2.4h-1
		c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7
		c0.1,0.2,0.3,0.5,0.3,0.8l0.1,0.4l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0
		l-0.1-0.1l-0.2-0.1l-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6
		c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9l-0.7,0.8l-0.3-0.8c-0.3-1-0.9-1.8-1.7-2.4c-0.8-0.6-1.9-0.7-2.7-0.5
		s-1.6,0.4-2.4,0.9c-0.8,0.5-1.5,1.1-2.4,1.8L535,790.6z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M430.2,1331.7c1.1-3.1,4.2-5.8,7.7-5.6c3.5,0.4,5.9,2.9,8.1,5.3h-1c3.1-6.6,11.4-7.7,15.1-1
		c0.1,0.3,0.4,0.8,0.5,1.1l0,0l0,0c0,0,0.1-0.1-0.1,0.1c-0.2,0.1,0,0-0.1,0.1l0,0c-0.1-0.1-0.3-0.3-0.4-0.4
		c-4.6-4.6-10-4.2-14.1,0.8l-0.6,0.7l-0.3-0.7c-2-4.6-7-5.4-11.1-3c-1.3,0.7-2.4,1.7-3.6,2.8L430.2,1331.7L430.2,1331.7z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M454,1346.2c0.4-2.2,2.5-4.4,5-4.3c2.5,0.5,3.8,2.4,5.4,4h-1c0.4-1,1-2,1.9-2.8c3.1-3,7.5-1,8.4,2.9
		c0,0,0.1-0.1-0.1,0.1c-0.2,0.1,0,0-0.1,0.1c-0.2-0.1-0.4-0.4-0.6-0.5c-3.7-3.3-6.4-1.8-9.2,1.6l-0.3-0.8c-1.1-3.3-4.1-3.7-6.8-2.1
		c-0.8,0.5-1.5,1.1-2.4,1.8L454,1346.2L454,1346.2z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M579.5,824.4c0.2-1.1,0.8-2.1,1.6-3c0.8-0.8,2.1-1.4,3.4-1.3c1.3,0.2,2.3,1,3.1,1.7s1.5,1.5,2.3,2.4h-1
		c0.4-1,1-2,1.9-2.8s2.1-1.4,3.4-1.4c1.3,0,2.5,0.6,3.4,1.5c0.2,0.2,0.4,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.5,0.7
		c0.1,0.2,0.3,0.5,0.3,0.8l0.1,0.4l0.1,0.2v0.1l0,0l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0
		l-0.1-0.1l-0.2-0.1l-0.3-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.6-2.4-0.6
		c-0.8,0.1-1.5,0.3-2.3,0.8c-0.7,0.5-1.4,1.2-2.1,1.9l-0.7,0.8l-0.3-0.8c-0.3-1-0.9-1.8-1.7-2.4c-0.8-0.6-1.9-0.7-2.7-0.5
		s-1.6,0.4-2.4,0.9c-0.8,0.5-1.5,1.1-2.4,1.8L579.5,824.4z"/>
</g>
<g>
	<path fill="#4D4D4D" d="M547.1,767.6c0.4-1.4,1.2-2.7,2.3-3.8c1.1-1,2.7-1.8,4.4-1.7c1.7,0.2,3,1.1,4.1,2.1s2.1,2,3,3.2h-1
		c0.6-1.3,1.4-2.7,2.6-3.7c1.2-1,2.7-1.8,4.4-1.8s3.3,0.8,4.4,1.8c0.3,0.3,0.5,0.5,0.8,0.8c0.2,0.3,0.5,0.6,0.6,0.9
		c0.2,0.3,0.4,0.6,0.5,1l0.2,0.5l0.1,0.2v0.1v0.1l0,0l0,0l0,0l0,0c0,0,0.1,0-0.1,0c-0.2,0.1,0,0-0.1,0l0,0l0,0l0,0l0,0l0,0l-0.1-0.1
		L573,767l-0.4-0.4c-0.2-0.3-0.5-0.5-0.8-0.7c-0.5-0.4-1-0.8-1.6-1.2c-1.1-0.6-2.2-1-3.4-0.9c-1.1,0.1-2.2,0.5-3.3,1.2
		c-1,0.7-1.9,1.7-2.8,2.8l-0.6,0.8l-0.3-0.8c-0.5-1.3-1.3-2.5-2.4-3.3c-1.1-0.8-2.5-1-3.7-0.9c-1.2,0.2-2.3,0.5-3.4,1.3
		c-1.1,0.7-2,1.7-3.1,2.7L547.1,767.6z"/>
</g>
<g>
	<path fill="#7CB0C2" d="M529.3,635.7c-1.2-1.7-0.6-4,1-5.2c6.7-4.8,10.9-13.8,10.9-19.4c0-14.7-20.1-25.6-38.8-18.3
		c-18,7-0.7,20.3-33.7,20.1c-35.8-0.3-64.5,15.6-64.5,34.9s28.9,34.9,64.5,34.9s64.5-15.6,64.5-34.9
		C533.3,643.5,531.9,639.4,529.3,635.7z"/>
	<ellipse fill="#7CB0C2" cx="553.2" cy="682.8" rx="24.3" ry="14"/>
</g>
<g>
	<path fill="#2D5615" d="M487.9,590.5c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7s1.4-3.7,2.3-5.4C485.5,593.6,486.6,592,487.9,590.5z"/>
</g>
<g>
	<path fill="#2D5615" d="M482,586.5c-1.3,4.8-2.4,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.6-10,1.6-15
		C479.3,596.1,480.5,591.2,482,586.5z"/>
</g>
<g>
	<path fill="#2D5615" d="M471.2,595.1c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C473.4,597.8,472.5,596.3,471.2,595.1z"/>
</g>
<g>
	<path fill="#2D5615" d="M474.6,591.7c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7s-0.7-3.8-1.4-5.5C476.6,595,475.8,593.2,474.6,591.7z"/>
</g>
<g>
	<path fill="#2D5615" d="M454.5,661.4c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7s1.4-3.7,2.3-5.4C452.2,664.6,453.3,662.9,454.5,661.4z"/>
</g>
<g>
	<path fill="#2D5615" d="M446.7,655.9c-1.3,4.8-2.4,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.6-10,1.6-15
		C444,665.6,445.2,660.7,446.7,655.9z"/>
</g>
<g>
	<path fill="#2D5615" d="M440.6,665.6c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C442.7,668.3,441.8,666.8,440.6,665.6z"/>
</g>
<g>
	<path fill="#2D5615" d="M443.9,662.2c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7s-0.7-3.8-1.4-5.5C446,665.5,445.1,663.7,443.9,662.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M465.5,661c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7s1.4-3.7,2.3-5.4C463.2,664.1,464.2,662.5,465.5,661z"/>
</g>
<g>
	<path fill="#2D5615" d="M459.2,655.9c-1.3,4.8-2.4,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.6-10,1.6-15
		C456.5,665.6,457.7,660.7,459.2,655.9z"/>
</g>
<g>
	<path fill="#2D5615" d="M448.1,667.1c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C450.2,669.9,449.3,668.3,448.1,667.1z"/>
</g>
<g>
	<path fill="#2D5615" d="M457,665.2c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9l-0.1,2.9
		l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7s-0.7-3.8-1.4-5.5C459.1,668.4,458.2,666.7,457,665.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M466.5,668.6c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-4,0.5-5.9c0.3-2,0.8-3.8,1.4-5.7s1.4-3.7,2.3-5.4C464.2,671.8,465.2,670.1,466.5,668.6z"/>
</g>
<g>
	<path fill="#2D5615" d="M454.6,661.4c-1.3,4.8-2.4,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.6-10,1.6-15
		C451.9,671.1,453.1,666.2,454.6,661.4z"/>
</g>
<g>
	<path fill="#2D5615" d="M441.5,668.6c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.5-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C443.7,671.4,442.8,669.8,441.5,668.6z"/>
</g>
<g>
	<path fill="#2D5615" d="M451.9,667.5c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-1,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7s-0.7-3.8-1.4-5.5C454,670.7,453.1,669,451.9,667.5z"/>
</g>
<g>
	<path fill="#2D5615" d="M517.5,1160.5c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4c-0.5,1.9-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-3.9,0.5-5.9c0.3-1.9,0.8-3.8,1.4-5.7c0.6-1.9,1.4-3.7,2.3-5.4C515.2,1163.6,516.3,1161.9,517.5,1160.5z"/>
</g>
<g>
	<path fill="#2D5615" d="M509.6,1155c-1.3,4.8-2.4,9.7-3.1,14.6c-0.8,4.9-1.2,9.9-1.1,14.8h-1c0-5,0.6-10,1.6-14.9
		S508.2,1159.7,509.6,1155z"/>
</g>
<g>
	<path fill="#2D5615" d="M503.6,1164.6c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.4-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C505.7,1167.3,504.8,1165.8,503.6,1164.6z"/>
</g>
<g>
	<path fill="#2D5615" d="M506.9,1161.2c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-0.9,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5
		C509,1164.5,508.1,1162.7,506.9,1161.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M528.5,1160c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-3.9,0.5-5.9c0.3-1.9,0.8-3.8,1.4-5.7s1.4-3.7,2.3-5.4C526.2,1163.2,527.2,1161.5,528.5,1160z"/>
</g>
<g>
	<path fill="#2D5615" d="M522.2,1155c-1.3,4.8-2.3,9.7-3.1,14.6s-1.2,9.9-1.1,14.8h-1c0-5,0.7-10,1.6-14.9S520.7,1159.7,522.2,1155z"
		/>
</g>
<g>
	<path fill="#2D5615" d="M511,1166.2c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.4-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C513.2,1168.9,512.3,1167.4,511,1166.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M520,1164.2c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-0.9,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5
		C522.1,1167.5,521.2,1165.7,520,1164.2z"/>
</g>
<g>
	<path fill="#2D5615" d="M529.5,1167.7c-1.2,1.5-2.2,3.2-3,5s-1.5,3.6-2.1,5.4s-1,3.8-1.2,5.7c-0.3,1.9-0.3,3.8-0.2,5.8h-1
		c0-2,0.2-3.9,0.5-5.9c0.3-1.9,0.8-3.8,1.4-5.7s1.4-3.7,2.3-5.4C527.2,1170.8,528.2,1169.2,529.5,1167.7z"/>
</g>
<g>
	<path fill="#2D5615" d="M517.5,1160.5c-1.3,4.8-2.3,9.7-3.1,14.6s-1.2,9.9-1.1,14.8h-1c0-5,0.7-10,1.6-14.9
		C514.9,1170.1,516.1,1165.2,517.5,1160.5z"/>
</g>
<g>
	<path fill="#2D5615" d="M504.5,1167.7c1.3,1.2,2.3,2.7,3.1,4.2c0.8,1.6,1.3,3.3,1.6,5c0.4,1.7,0.5,3.5,0.5,5.3c0,0.9-0.1,1.8-0.1,2.6
		l-0.3,2.6l-1-0.2l0.4-2.5c0.1-0.9,0.2-1.7,0.2-2.6c0.1-1.7,0.1-3.4-0.2-5.2c-0.3-1.7-0.7-3.4-1.4-5
		C506.6,1170.4,505.8,1168.9,504.5,1167.7z"/>
</g>
<g>
	<path fill="#2D5615" d="M514.9,1166.5c1.3,1.4,2.2,3.2,3,4.9c0.8,1.8,1.3,3.7,1.7,5.5c0.4,1.9,0.6,3.8,0.7,5.8c0,1,0,1.9,0,2.9
		l-0.1,2.9l-1-0.1l0.3-2.8c0-0.9,0.1-1.9,0.1-2.8c0-1.9-0.1-3.8-0.4-5.7c-0.3-1.9-0.7-3.8-1.4-5.5
		C517,1169.8,516.1,1168,514.9,1166.5z"/>
</g>
<path fill="#FFFFFF" d="M512.1,650.5c0-0.1,0-0.2,0-0.3c0-3.1-3.5-5.7-7.9-5.7s-7.9,2.5-7.9,5.7c0,0.1,0,0.2,0,0.3H512.1z"/>
<path fill="#FFFFFF" d="M500.1,638.6c0,0-1.2-0.8-2.4-0.3c-1.1,0.4-1.8,1.3-1.5,2s1.4,1,2.5,0.5c0.5-0.2,0.9-0.5,1.1-0.8
	c0.5,0.7,0.7,1.7-0.2,3.1c-2,3-3.5,4.6-3.2,7.4h1.2c-0.2-2.8,1.6-4.1,3.2-7.4C502.3,640.1,500.1,638.6,500.1,638.6z"/>
<path fill="#333333" d="M496.2,640.8c0,0,0,1.3-0.5,2.1c1.1-0.3,1.9-1.8,1.9-1.8l-1.2-0.6C496.4,640.4,495.9,640.3,496.2,640.8z"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="40" y1="797.2" x2="40" y2="-691.9" gradientTransform="matrix(1 0 0 1 0 834)">
	<stop  offset="0" stopColor="#2A7DE1"/>
	<stop  offset="0.45" stopColor="#5981B1"/>
	<stop  offset="0.8024" stopColor="#778392"/>
	<stop  offset="1" stopColor="#828487"/>
</linearGradient>
<line fill="none" stroke="url(#SVGID_1_)" strokeMiterlimit="10" strokeWidth="9" x1="40" y1="142.1" x2="40" y2="1631.2"/>
<circle fill="#005ADA" cx="40" cy="142.1" r="66"/>
<circle fill="#FFAD00" cx="40" cy="276.4" r="26"/>
<circle fill="#005ADA" cx="40" cy="690.7" r="66"/>
<circle fill="#828487" cx="40" cy="1682.7" r="66"/>
<circle fill="#FFAD00" cx="40" cy="548.1" r="26"/>
<circle fill="#FFAD00" cx="40" cy="826.4" r="26"/>
<rect x="132" y="901.8" fill="none" width="254.6" height="124.3"/>
<text transform="matrix(1 0 0 1 132 916.7122)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2019</text>
<text transform="matrix(1 0 0 1 132 934.7122)" font-family="Lato" fontSize="18">Clients spread across the B2B </text>
<text transform="matrix(1 0 0 1 132 952.7122)" font-family="Lato" fontSize="18">tech landscape. </text>
<text transform="matrix(1 0 0 1 132 982.7122)" font-family="Lato" fontSize="18">Instagram influencer program </text>
<text transform="matrix(1 0 0 1 132 1000.7122)" font-family="Lato" fontSize="18">for B2B companies a big </text>
<text transform="matrix(1 0 0 1 132 1018.7122)" font-family="Lato" fontSize="18">success.</text>
<circle fill="#FFAD00" cx="40" cy="934.6" r="26"/>
<circle fill="#FFAD00" cx="40" cy="1093.5" r="26"/>
<rect x="132" y="1186.5" fill="none" width="279.6" height="99.3"/>
<text transform="matrix(1 0 0 1 131.9999 1201.3766)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2021</text>
<text transform="matrix(1 0 0 1 131.9999 1219.3766)" font-family="Lato" fontSize="18">B2B client list grows across </text>
<text transform="matrix(1 0 0 1 131.9999 1237.3766)" font-family="Lato" fontSize="18">sectors, from cybersecurity to </text>
<text transform="matrix(1 0 0 1 131.9999 1255.3766)" font-family="Lato" fontSize="18">multi-cloud networking to </text>
<text transform="matrix(1 0 0 1 131.9999 1273.3766)" font-family="Lato" fontSize="18">healthcare.</text>
<circle fill="#FFAD00" cx="40" cy="1214.8" r="26"/>
<circle fill="#FFAD00" cx="40" cy="1371.7" r="26"/>
<circle fill="#FFAD00" cx="40" cy="1501.4" r="26"/>
<circle fill="#005ADA" cx="40" cy="411.1" r="66"/>
<g>
	<path fill="#A37C52" d="M584.7,1525.7c-3.9,1.4-6.2,3-9.8,4.3c-2.2-0.8-23.1-8.1-25.2-9.1c-0.4-0.2-0.6-1.5-0.5-2.2
		c0.1-0.9,0.4-1.9,0.9-2.7c1.1-1.8,2.1-2,3.7-1.4c4,1.5,8.1,2.9,12.2,4.3C566.3,1519.1,584.1,1525.5,584.7,1525.7z"/>
	<path fill="#A37C52" d="M583.1,1533.1c-4.2-1.5-13.4-4.9-17.6-6.4c2.1-0.8,9.2,0.7,11,0c1.1-0.4,2.3-0.6,3.3-1.2
		c2-1.1,3.8-0.6,5.7,0.4C583.5,1527.8,582.6,1530.2,583.1,1533.1z"/>
	<path fill="#A37C52" d="M549,1528.5c0.3-0.4,0.6-1.4,1.3-1.8c0.9-0.6,1.8,0.2,2.4,1c1.1,1.4,1.6,2.9,1.2,4.6c-0.4,1.6-1.5,2-2.8,1
		C549.7,1532.2,549.2,1530.7,549,1528.5z"/>
	<path fill="#B09F8E" d="M589,1528.5c-0.2,2.1-0.6,3.6-1.8,4.7c-1.3,1.3-2.7,0.8-3-0.9c-0.3-1.7,0.2-3.1,1.2-4.4
		c0.6-0.8,1.4-1.7,2.4-1.1C588.5,1527.1,588.8,1528.1,589,1528.5z"/>
	<path fill="#FFAD00" d="M568,1476.6c1.8,2.2,3,4.5,4,6.8c1.6,3.9,2.2,7.9,2.1,12.1c0,0.4,0.2,0.9,0.3,1.3c0.4-0.2,0.8-0.3,1.1-0.6
		c0.7-0.7,1.4-1.5,2.2-2.3c3.4,3.4,4.8,7.3,4,11.9c-0.7,4.1-3,7.1-6.7,9.2c0.2-0.8,0.3-1.4,0.3-2c0.2-1.9-0.3-3.7-1.7-5.1
		c-2-2.1-3.1-4.6-3.5-7.5c0-0.4-0.3-0.7-0.5-1c-0.3,0.2-0.7,0.4-1,0.7c-1.7,2.2-2.6,4.7-2.8,7.7c-0.8-0.8-1.3-0.3-1.8,0.2
		c-1.6,1.8-2,3.8-1.5,6c0,0.2,0.1,0.4,0.2,0.7c0,0,0,0.1-0.1,0.2c-2.2-1.2-3.9-2.9-5.1-5.1c-2.7-4.9-2.1-10.9,1.7-14.9
		C564.2,1489.7,567.2,1483.8,568,1476.6z"/>
	<path fill="#B09F8E" d="M555,1533c0.5-2.8-0.3-5.1-2.5-7.1c1.8-0.6,3.5-1.2,5.1-1.8c9-3.2,18-6.3,27-9.4c1.4-0.5,2.1-0.3,3,0.8
		c1.2,1.5,1.8,3.1,1.2,5c-0.2,0.5-0.7,1.1-1.2,1.2c-10.8,3.8-21.6,7.6-32.4,11.3C555.2,1533,555.2,1533,555,1533z"/>
</g>
<rect x="129.7" y="1638.7" fill="none" width="306.9" height="92.7"/>
<text transform="matrix(1 0 0 1 129.7199 1653.5259)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">Today</text>
<text transform="matrix(1 0 0 1 129.7199 1675.1259)" enable-background="new"><tspan x="0" y="0" font-family="Lato" fontSize="18">Large team managing over</tspan><tspan x="0" y="21.6" font-family="Lato" fontSize="18">112 social profiles, 21 Hootsuite accounts,</tspan><tspan x="0" y="43.2" font-family="Lato" fontSize="18">15 animals, and 13 kids.</tspan></text>
<g>
	<g>
		<rect x="470.5" y="1625.8" fill="#B09F8E" width="5.2" height="20.5"/>
		<polygon fill="#3B7558" points="462.8,1615.8 473.2,1587.9 483.6,1615.8 		"/>
		<polygon fill="#3B7558" points="458,1636 473.2,1595.5 488.4,1636 		"/>
	</g>
	<g>
		<rect x="560.8" y="1711.8" fill="#B09F8E" width="7.7" height="30"/>
		<polygon fill="#3B7558" points="549.5,1697.1 564.8,1656.3 580.1,1697.1 		"/>
		<polygon fill="#3B7558" points="542.5,1726.8 564.8,1667.5 587,1726.8 		"/>
	</g>
	<path fill="#A9C4AB" d="M498.1,1696c-0.5-0.9-0.8-1.9-0.8-3c0-2.7,1.8-4.9,4.4-6c1.1-2.9,4.4-5,8.2-5c4.8,0,8.6,3.2,8.6,7.2
		c0,0.7-0.1,1.4-0.4,2.1c2.4,0.4,4.2,2.1,4.2,4.1c0,0.2,0,0.3,0,0.5h-24.2V1696z"/>
	<path fill="#4D4D4D" d="M587.4,1640.9c3.8-4.4,10-6.2,12.6,0.2l0.3,0.8l0.6-0.8c3.4-4.6,7.6-5.4,11.8-1.1c0.2,0.2,0.5,0.5,0.8,0.7
		l0,0c0,0-0.1,0.1,0.1,0c0.2-0.1,0,0,0.1-0.1c-0.9-2.9-3.4-5.5-6.7-5.6c-3.3,0-5.8,2.7-7,5.5h1c-2.8-3.6-7.1-7.6-11.4-3.6
		c-1.1,1.1-1.9,2.4-2.3,3.8L587.4,1640.9L587.4,1640.9z"/>
</g>
<polygon fill="none" points="129.7,1443.9 447.9,1460.9 447.9,1531.9 129.7,1571.9 "/>
<text transform="matrix(1 0 0 1 129.7197 1458.7958)" fill="#2A7DE1" font-family="Lato" font-weight="bold" fontSize="20">2023</text>
<text transform="matrix(1 0 0 1 129.7197 1480.3958)" font-family="Lato" fontSize="18">Helped clients manage social media at </text>
<text transform="matrix(1 0 0 1 129.7197 1501.9958)" font-family="Lato" fontSize="18">in-person events.</text>
<text transform="matrix(1 0 0 1 129.7197 1533.5957)" font-family="Lato" fontSize="18">Provided trainings for clients on </text>
<text transform="matrix(1 0 0 1 129.7197 1555.1958)" font-family="Lato" fontSize="18">employee social engagement platforms.</text>
<circle fill="#828487" cx="-89.5" cy="1689.5" r="14"/>
<circle fill="#005ADA" cx="40" cy="956.6" r="66"/>
<circle fill="#005ADA" cx="40" cy="1224.8" r="66"/>
<circle fill="#005ADA" cx="40" cy="1501.4" r="66"/>
  </Svg>
);
