import React, { FC } from 'react';

import { Svg } from '@components/core';

interface IOwnProps extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
}

export const GraphicDesign: FC<IOwnProps> = ({ className = '' }) => (
  <Svg
    aria-labelledby="GraphicDesignTitle"
    className={className}
    role="img"
    viewBox="0 0 152 152"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="GraphicDesignTitle">GraphicDesign Icon</title>
    <circle cx="76" cy="76" fill="#2a7de1" r="76" />
    <circle cx="75.13" cy="76.5" fill="#ffa700" r="64" />
    <path
      d="M106.57 118.39H43.68c-1.73 0-3.14-1.4-3.14-3.14V72.71c0-1.73 1.4-3.14 3.14-3.14h62.89c1.73 0 3.14 1.4 3.14 3.14v42.54c0 1.74-1.4 3.14-3.14 3.14z"
      fill="#fff"
    />
    <path
      d="M107.53 69.58H42.72c-1.2 0-2.56-.95-3.04-2.15l-9.02-22.44c-.78-1.95-.15-3.59 1.45-3.59h86.04c1.59 0 2.23 1.63 1.45 3.59l-9.02 22.44c-.49 1.19-1.84 2.15-3.05 2.15z"
      fill="#324a5e"
    />
    <path d="M44.79 67.15L37.33 45.4h75.6l-7.46 21.75z" fill="#2a7de1" />
    <path
      d="M49.42 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM57.34 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM65.26 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73zM73.17 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM81.09 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM89.01 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73zM96.92 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM104.84 79.55h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
      fill="#dde0e5"
    />
    <g>
      <path
        d="M49.42 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM57.34 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM65.26 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73zM73.17 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM81.09 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM89.01 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73zM96.92 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM104.84 86.83h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M49.42 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM57.34 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM65.26 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73zM73.17 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM81.09 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM89.01 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73zM96.92 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73zM104.84 94.11h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M49.42 101.39h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M81.09 101.39H53.34c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h27.75c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M89.01 101.39h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4a.74.74 0 0 1-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M96.92 101.39h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M104.84 101.39h-4c-.4 0-.73-.33-.73-.73v-4c0-.4.33-.73.73-.73h4c.4 0 .73.33.73.73v4c0 .4-.33.73-.73.73z"
        fill="#dde0e5"
      />
    </g>
    <g>
      <path
        d="M85.14 115.7H65.12c-.76 0-1.37-.61-1.37-1.37v-8.47c0-.76.61-1.37 1.37-1.37h20.03c.76 0 1.37.61 1.37 1.37v8.47c-.01.75-.62 1.37-1.38 1.37z"
        fill="#dde0e5"
      />
    </g>
  </Svg>
);
