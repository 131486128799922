import React, { FC } from 'react';

import { Svg } from '@components/core';

interface IOwnProps extends React.SVGAttributes<SVGSVGElement> {
  className?: string;
}

export const SocialStrategy: FC<IOwnProps> = ({ className = '' }) => (
  <Svg
    aria-labelledby="SocialStrategyTitle"
    className={className}
    role="img"
    viewBox="0 0 152 152"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="SocialStrategyTitle">SocialStrategy Icon</title>
    <circle cx="76" cy="76" fill="#2a7de1" r="76" />
    <path
      d="M139.74 76.8c0 28.99-19.27 53.48-45.7 61.34a63.916 63.916 0 0 1-18.3 2.66c-7.04 0-13.81-1.13-20.14-3.23-25.48-8.44-43.86-32.46-43.86-60.77 0-35.34 28.65-64 64-64s64 28.66 64 64z"
      fill="#fff"
    />
    <path
      d="M75.74 24.8c-28.67 0-52 23.33-52 52s23.33 52 52 52 52-23.33 52-52-23.32-52-52-52zm2 99.95v-13.81l2.35 2.35c.39.39.9.59 1.41.59.51 0 1.02-.19 1.41-.59.78-.78.78-2.05 0-2.83l-5.77-5.77c-.78-.78-2.05-.78-2.83 0l-5.77 5.77c-.78.78-.78 2.05 0 2.83.78.78 2.05.78 2.83 0l2.35-2.35v13.81c-24.88-1.03-44.92-21.07-45.95-45.95h13.81l-2.35 2.35a2.004 2.004 0 0 0 1.41 3.42c.51 0 1.02-.2 1.41-.59l5.77-5.77a1.983 1.983 0 0 0 0-2.82l-5.77-5.77c-.78-.78-2.05-.78-2.83 0-.78.78-.78 2.05 0 2.83l2.35 2.35H27.76c1.03-24.88 21.07-44.92 45.95-45.95v13.81l-2.35-2.35c-.78-.78-2.05-.78-2.83 0-.78.78-.78 2.05 0 2.83l5.77 5.77c.39.39.9.59 1.41.59.51 0 1.02-.2 1.41-.59l5.77-5.77c.78-.78.78-2.05 0-2.83-.78-.78-2.05-.78-2.83 0l-2.35 2.35V28.85c24.88 1.03 44.92 21.07 45.95 45.95h-13.81l2.35-2.35c.78-.78.78-2.05 0-2.83-.78-.78-2.05-.78-2.83 0l-5.77 5.77a1.983 1.983 0 0 0 0 2.82l5.77 5.77c.39.39.9.59 1.41.59a2.004 2.004 0 0 0 1.41-3.42l-2.35-2.35h13.81c-.98 24.88-21.03 44.93-45.91 45.95z"
      fill="#ffa700"
    />
    <path
      d="M92.88 95.04c0 .15 0 .3-.01.45-4.48 2.99-10.5 4.81-17.12 4.81-6.63 0-12.65-1.83-17.13-4.82-.01-.15-.01-.3-.01-.44 0-9.46 7.68-17.14 17.14-17.14s17.13 7.67 17.13 17.14zM83.72 64.85c0-.54-.19-1.02-.49-1.39.02-.31.03-.61.03-.91 0-5.33-3.37-7.35-7.52-7.35s-7.52 2.02-7.52 7.35c0 .3.01.61.03.91-.3.37-.49.85-.49 1.39 0 .82.44 1.52 1.06 1.86 1.15 4.1 3.81 7.8 6.92 7.8 3.1 0 5.77-3.7 6.92-7.8.63-.34 1.06-1.04 1.06-1.86z"
      fill="#2a7de1"
    />
  </Svg>
);
